import { Popover } from "@compoundfinance/design-system";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse, AxiosError } from "axios";
import debounce from "debounce";
import React, { ReactNode, useState } from "react";
import { useIsMounted } from "components/UseIsMounted";
import { Filter, PaginatedResponse } from "lib/api";
import useNotification from "providers/NotificationProvider/useNotification";
import { notEmptyString } from "shared/utilities";
import classNames from "shared/utilities/classNames";

const DEFAULT_SEARCH_LIMIT = 5;

interface GenericBase {
  readonly id: string;
  readonly name?: string; // TODO: Would be nice to make this more generic
}

interface SharedProps<T extends GenericBase> {
  readonly id?: string;
  readonly selectedItem?: T | null;
  readonly initialSearchTerm?: string;
  readonly setSelectedItem: (item?: T | null) => void;
  /**
   * By default, displays the `name` field or falls back to empty string if `item` is `undefined`
   */
  readonly renderSelectedItemText?: (item: T | undefined) => string;
  readonly renderItemOptionDisplay?: (item: T, active: boolean, selected: boolean) => ReactNode;
  readonly optionDataProperty?: (item: T) => string;
  readonly inputPlaceholder?: string;
  readonly inputDataProperty?: string;
  readonly onBlur?: React.FocusEventHandler<HTMLInputElement>;
  readonly onFocus?: React.FocusEventHandler<HTMLInputElement>;
  readonly additionalStyleProperties?: string;
  readonly disabled?: boolean;
  readonly autoFocus?: boolean;
  readonly showSearchIcon?: boolean;
  readonly variant?: "compound";
  readonly align?: "left" | "right";
}

interface LocalAutocompleteSearchProps<T extends GenericBase> extends SharedProps<T> {
  readonly allItems: T[];

  readonly searchEndpoint?: never;
  readonly filterKey?: never;
  readonly searchLimit?: never;
  readonly itemIdsToExclude?: never;
}

interface ServerAutocompleteSearchProps<T extends GenericBase> extends SharedProps<T> {
  // If there's a filter key, we are using the new generic filter pattern
  readonly searchEndpoint: string;
  readonly filterKey?: string;
  readonly searchLimit?: number;
  readonly itemIdsToExclude?: string[];

  readonly allItems?: never;
}

type Props<T extends GenericBase> =
  | LocalAutocompleteSearchProps<T>
  | ServerAutocompleteSearchProps<T>;

const isLocalAutocompleteSearch = <T extends GenericBase>(
  props: Props<T>
): props is LocalAutocompleteSearchProps<T> => "allItems" in props && Array.isArray(props.allItems);

const isServerAutocompleteSearch = <T extends GenericBase>(
  props: Props<T>
): props is ServerAutocompleteSearchProps<T> =>
  "searchEndpoint" in props && typeof props.searchEndpoint === "string";

const getItemList = <T extends GenericBase>({
  serverItemList,
  localItemList,
  searchTerm,
  shouldUseServerItems,
  shouldUseLocalItems,
}: {
  serverItemList: T[] | null | undefined | void;
  localItemList: T[] | null | undefined | void;
  searchTerm: string;
  shouldUseServerItems: boolean;
  shouldUseLocalItems: boolean;
}) => {
  if (shouldUseLocalItems) {
    const regex = new RegExp(searchTerm, "i");
    return localItemList?.filter((item: T) => !!item.name && regex.test(item.name)) ?? [];
  }
  if (shouldUseServerItems) {
    return serverItemList ?? [];
  }
  return [];
};

const defaultOptionDataProperty = <T extends GenericBase>(item: T) => item.id;

const AutocompleteSearch = <T extends GenericBase>({
  optionDataProperty = defaultOptionDataProperty<T>,
  ...props
}: Props<T>) => {
  const {
    renderSelectedItemText = (item) => item?.name ?? "",
    renderItemOptionDisplay = (item) => item.name ?? "",
  } = props;

  const isMounted = useIsMounted(); /** Prevents hydration mismatches */

  const [searchTerm, setSearchTerm] = useState<string>(props.initialSearchTerm ?? "");

  const { showErrorNotification } = useNotification();

  const { data: serverItemList } = useQuery({
    queryKey: isServerAutocompleteSearch(props)
      ? [
          props.searchEndpoint,
          {
            searchTerm,
            filterKey: props.filterKey,
            limit: props.searchLimit,
            itemIdsToExclude: props.itemIdsToExclude,
          },
        ]
      : ["local-autocomplete-search"],
    queryFn: () => {
      if (!isServerAutocompleteSearch(props)) {
        return null;
      }

      // Use this for the new filter pattern if the `filterKey` is specified.
      const filters: Filter[] = [
        {
          filterKey: props.filterKey ?? "",
          filterValues: [searchTerm],
          type: "search",
          urlParamName: "search",
        },
      ];

      return axios
        .get(props.searchEndpoint, {
          params: {
            search: props.filterKey ? undefined : searchTerm, // "Legacy" search pattern
            limit: props.searchLimit ?? DEFAULT_SEARCH_LIMIT,
            filters: props.filterKey ? filters : searchTerm, // Newly improved generic search pattern
          },
          // This is necessary to serialize an array in a request query properly with axios.
          // See: https://github.com/axios/axios/issues/5058
          paramsSerializer: {
            indexes: null,
          },
        })
        .then(async (response: AxiosResponse<PaginatedResponse<T>>) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }
          return props.itemIdsToExclude !== undefined
            ? response.data.items.filter((item) => !props.itemIdsToExclude!.includes(item.id))
            : response.data.items;
        })
        .catch((error: AxiosError) => {
          console.error(error);
          showErrorNotification("Something went wrong with your search");
        });
    },
    enabled: isServerAutocompleteSearch(props) && notEmptyString(searchTerm),
  });

  const itemList = getItemList({
    serverItemList,
    localItemList: isLocalAutocompleteSearch(props) ? props.allItems : [],
    searchTerm,
    shouldUseLocalItems: isLocalAutocompleteSearch(props),
    shouldUseServerItems: isServerAutocompleteSearch(props),
  });

  const searchForItem = async (search: string) => {
    setSearchTerm(search);
  };

  // https://lawsofux.com/doherty-threshold/
  const queryChanged = debounce(searchForItem, 350);

  return (
    <div
      className={classNames(props.variant !== "compound" ? "flex-1" : "")}
      suppressHydrationWarning
    >
      <Combobox
        value={props.selectedItem}
        onChange={props.setSelectedItem}
        disabled={props.disabled ?? false}
        nullable
      >
        <Popover open={isMounted}>
          <Popover.Trigger asChild>
            <div className="relative items-center text-alternativ-monochrome-dark p-1">
              {props.showSearchIcon && (
                <MagnifyingGlassIcon className="absolute top-1/2 left-0 h-4 -translate-y-1/2 px-3" />
              )}
              <Combobox.Input
                id={props.id}
                className={classNames(
                  "w-full truncate rounded-md sm:text-sm",
                  !props.variant
                    ? "border border-gray-300 bg-white py-2 pl-3 pr-20 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500"
                    : "border-none focus:border-b-2 focus:ring-0 -ml-2.5",
                  props.showSearchIcon ? "pl-9" : "",
                  props.additionalStyleProperties ? props.additionalStyleProperties : "",
                  props.disabled ? "text-gray-500" : ""
                )}
                onChange={(event) => queryChanged(event.target.value)}
                displayValue={renderSelectedItemText}
                placeholder={props.inputPlaceholder}
                autoComplete="off"
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                autoFocus={props.autoFocus}
                data-at={props.inputDataProperty}
              />
              {props.variant !== "compound" && (
                <Combobox.Button
                  className="text-alternativ-monochrome-light-light absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 hover:text-alternativ-monochrome-medium focus:outline-none"
                  data-at={`${props.inputDataProperty}-select`}
                >
                  {!props.disabled && <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />}
                </Combobox.Button>
              )}
            </div>
          </Popover.Trigger>

          <Popover.Portal>
            <Popover.Content
              side="bottom"
              sideOffset={0}
              align={props.align === "right" ? "end" : "start"}
              alignOffset={4}
              style={{
                all: "unset",
                zIndex: 20,
              }}
              hideArrow
            >
              {itemList.length > 0 && (
                <Combobox.Options
                  className={classNames(
                    "max-h-60 divide-y overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  )}
                >
                  {itemList.map((item) => (
                    <Combobox.Option
                      key={item.id}
                      value={item}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default select-none py-2 pl-3 pr-12",
                          active ? "bg-accent-blue text-white" : "text-gray-900"
                        )
                      }
                      data-option={optionDataProperty(item)}
                    >
                      {({ active, selected }) => (
                        <>{renderItemOptionDisplay(item, active, selected)}</>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </Popover.Content>
          </Popover.Portal>
        </Popover>
      </Combobox>
    </div>
  );
};

export default AutocompleteSearch;
