import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { ModifiableRow } from "./menu/ModifiableRow";
import { OrganizationLogoRow } from "./menu/OrganizationLogoRow";
import { useMutateOrganizationQuery, useOrganizationQuery } from "../queries";
import Button from "components/fundamentals/Button";
import { LoadingIcon } from "components/fundamentals/icons/LoadingIcon";
import {
  AlertBanner,
  AlertBannerAlertProps,
  getDefaultErrorAlertProps,
  getDefaultSuccessAlertProps,
} from "feature/alerts";

interface Props {
  readonly orgId: string;
  readonly hasEditPermissions: boolean;
  readonly closeMenu: () => void;
}

export const OrganizationMenu = (props: Props): React.ReactElement => {
  const { data: orgQueryData, status: orgQueryStatus } = useOrganizationQuery(props.orgId);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<AlertBannerAlertProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const orgMutation = useMutateOrganizationQuery();
  const router = useRouter();

  const onSaveValue = async (modifiedValue: string, valueKey: string, successMessage: string) => {
    setShowAlert(false);
    orgMutation.mutate({
      orgId: orgQueryData?.id ?? "",
      [valueKey]: modifiedValue,
      onMutate: () => {
        setAlertProps(getDefaultSuccessAlertProps("orgEdit", successMessage));
        setShowAlert(true);
      },
      onError: () => {
        // uh oh. Something actually went wrong. Alert the user
        setAlertProps(getDefaultErrorAlertProps("orgEdit", "Something went wrong."));
        setShowAlert(true);
      },
    });
  };
  return (
    <>
      {showAlert && alertProps && <AlertBanner alerts={[alertProps]} />}

      {orgQueryData && (
        <div className="py-6" data-at="orgId" data-org-id={orgQueryData.id}>
          <div className="px-4" data-at="organization-menu">
            <div className="flex items-center justify-between">
              <Dialog.Title className="ml-2 text-lg font-medium text-gray-900">
                Firm Details
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                  onClick={props.closeMenu}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="relative mt-6 flex-1 px-4">
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <div className="sm:divide-y sm:divide-gray-200">
                <ModifiableRow
                  rowId="name"
                  rowLabel="Name"
                  rowValue={orgQueryData.name}
                  hasEditPermissions={props.hasEditPermissions}
                  onSaveValue={async (modifiedValue: string) => {
                    await onSaveValue(modifiedValue, "name", "Name saved!");
                  }}
                />
                <ModifiableRow
                  rowId="websiteUrl"
                  rowLabel="Website"
                  rowValue={orgQueryData.websiteUrl ?? ""}
                  rowValueStyled={
                    orgQueryData.websiteUrl ? (
                      <a
                        href={orgQueryData.websiteUrl}
                        className="underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {orgQueryData.websiteUrl}
                      </a>
                    ) : (
                      <span>No Website listed</span>
                    )
                  }
                  hasEditPermissions={props.hasEditPermissions}
                  onSaveValue={async (modifiedValue: string) => {
                    await onSaveValue(modifiedValue, "websiteUrl", "Website URL saved!");
                  }}
                />
                <OrganizationLogoRow
                  orgId={props.orgId}
                  hasEditPermissions={props.hasEditPermissions}
                  orgName={orgQueryData.name}
                  logo={orgQueryData.logo}
                  setShowAlert={(show: boolean) => setShowAlert(show)}
                  setSuccessAlertProps={() =>
                    setAlertProps(getDefaultSuccessAlertProps("orgEdit", "Logo saved!"))
                  }
                  setErrorAlertProps={() =>
                    setAlertProps(getDefaultErrorAlertProps("orgEdit", "Something went wrong."))
                  }
                />
              </div>
            </div>
          </div>
          <div className="px-4">
            <Button
              fullWidth
              isPrimary
              size="medium"
              disabled={loading}
              submitting={loading}
              showLoadingIconOnSubmitting
              onClick={async () => {
                setLoading(true);
                await router.push(`/firm/${props.orgId}`).finally(() => setLoading(false));
              }}
            >
              More details
            </Button>
          </div>
        </div>
      )}
      {orgQueryStatus === "pending" && (
        <div className="flex">
          <span className="mr-2">Loading...</span>
          <LoadingIcon type="DARK" />
        </div>
      )}
      {orgQueryStatus === "error" && <div>Something went wrong loading firm information.</div>}
    </>
  );
};
