/* eslint-disable no-nested-ternary */
import {
  CheckIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Button from "components/fundamentals/Button";
import { Tooltip } from "components/fundamentals/Tooltip";
import classNames from "shared/utilities/classNames";

const rowStyling = "py-4 px-2 items-center grid grid-cols-12";
const rowLabelStyling = "text-sm font-medium text-gray-500 col-span-3 pr-2 flex items-center";
const rowBodyStyling =
  "mt-1 flex items-center justify-between text-sm text-gray-900 col-span-9 mt-0";

interface Props {
  readonly rowId: string;
  readonly rowLabel: string;
  // Need to pass in the raw value so we can prefill the input field when it's being edited
  readonly rowValue: string;
  // Will contained the `rowValue` but with additional styling around it. (ex: Link)
  readonly rowValueStyled?: React.ReactElement;
  readonly hasEditPermissions: boolean;
  readonly onSaveValue: (modifiedValue: string) => void;
  readonly tooltipContent?: React.ReactElement;
}

export const ModifiableRow = (props: Props) => {
  const [editingValue, setEditingValue] = useState<boolean>(false);
  const [modifiedValue, setModifiedValue] = useState<string>("");

  return (
    <div className={classNames(rowStyling)}>
      <div className={rowLabelStyling}>
        {props.rowLabel}
        {props.tooltipContent && (
          <Tooltip content={props.tooltipContent} justification="left">
            <QuestionMarkCircleIcon width={20} height={20} color="gray-500" />
          </Tooltip>
        )}
      </div>
      <div className={rowBodyStyling} data-at={`org-${props.rowId}`}>
        {editingValue && props.hasEditPermissions ? (
          <input
            className="border-1 w-full rounded-md border border-solid p-2"
            value={modifiedValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setModifiedValue(e.currentTarget.value);
            }}
          />
        ) : props.rowValueStyled ? (
          props.rowValueStyled
        ) : (
          <div>{props.rowValue}</div>
        )}
        {props.hasEditPermissions &&
          (editingValue ? (
            <div className="flex flex-row pl-2">
              <Button
                additionalStyleClasses="mr-2"
                isPrimary
                onClick={async () => {
                  setEditingValue(false);
                  props.onSaveValue(modifiedValue);
                }}
              >
                <CheckIcon height={20} width={20} />
              </Button>
              <Button onClick={() => setEditingValue(false)}>
                <XMarkIcon height={20} width={20} />
              </Button>
            </div>
          ) : (
            <button
              type="button"
              onClick={() => {
                setModifiedValue(props.rowValue);
                setEditingValue(true);
              }}
            >
              <PencilIcon height={20} width={20} />
            </button>
          ))}
      </div>
    </div>
  );
};
