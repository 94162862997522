import {
  UseQueryResult,
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  InfiniteData,
  keepPreviousData,
} from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import Error from "next/error";
import {
  GetAllOrganizationsResponse,
  GetOrganizationResponse,
  Organization,
  UpdateOrganizationRequest,
  UpdateOrganizationResponse,
} from "./models";

export const organizationKeys = {
  complete: ["organizations-complete"] as const,
  all: ["organizations"] as const,
  id: (orgId: string) => [...organizationKeys.all, orgId] as const,
};

export const useOrganizationQuery = (
  orgId: string,
  disabled?: boolean
): UseQueryResult<Organization, Error> =>
  useQuery({
    queryKey: organizationKeys.id(orgId),
    queryFn: async () => {
      const response: AxiosResponse<GetOrganizationResponse> = await axios.request({
        method: "GET",
        url: `/api/organization/${orgId}`,
      });
      return response.data.organization;
    },
    // Organization info doesn't change frequently. If organization is modified in any way, we should invalidate query cache
    staleTime: Infinity,
    enabled: !disabled,
  });

export interface OrgMutationProps {
  readonly orgId: string;
  readonly name?: string;
  readonly logo?: string;
  readonly websiteUrl?: string;
  readonly orionId?: string;
  // Additional custom mutate/"success" logic you want to run on top of the default included here (ex: show success banner)
  readonly onMutate?: () => void;
  // Additional custom onError logic you want to run on top of the default included here (ex: show error banner)
  readonly onError?: () => void;
}

export const useMutateOrganizationQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (props: OrgMutationProps) =>
      axios.request<
        UpdateOrganizationResponse,
        AxiosResponse<UpdateOrganizationResponse>,
        UpdateOrganizationRequest
      >({
        url: `/api/organization/${props.orgId}`,
        method: "PUT",
        data: {
          name: props.name,
          logo: props.logo,
          websiteUrl: props.websiteUrl,
          orionId: props.orionId,
        },
      }),

    onMutate: async (props: OrgMutationProps) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [organizationKeys.id(props.orgId), props.orgId],
      });

      // Snapshot the previous value
      const beforeUpdate: Organization | undefined = queryClient.getQueryData(
        organizationKeys.id(props.orgId)
      );

      if (beforeUpdate) {
        // Here we are trying to update the new org with any properties from the request.
        // Otherwise we are going to use any that were stored in the query cache
        const updatedOrg: Organization = {
          ...beforeUpdate,
          id: props.orgId,
          name: props.name ?? beforeUpdate.name,
          logo: props.logo ?? beforeUpdate.logo,
          websiteUrl: props.websiteUrl ?? beforeUpdate.websiteUrl,
          orionId: props.orionId ?? beforeUpdate.orionId,
        };

        // Optimistically update to the new value
        queryClient.setQueryData(organizationKeys.id(props.orgId), updatedOrg);

        // Run any custom logic that the consuming component wants to execute (ex: Success banner)
        if (props.onMutate) {
          props.onMutate();
        }

        // Return a context with the organization before and after the update.
        return { beforeUpdate, updatedOrg };
      }
      return { beforeUpdate };
    },

    onSuccess: () => {
      // This probably won't be used. Since we are using optimistic updates.
      // We are optimistic that it will work as expected and will update the page as if everything succeeded in the onMutate method.
    },

    // If the mutation fails, use the context we returned above
    onError: (error, vars, context) => {
      if (vars.onError) {
        vars.onError();
      }
      queryClient.setQueryData(organizationKeys.id(vars.orgId), context?.beforeUpdate);
    },

    // Always refetch after error or success
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: organizationKeys.all,
      });
    },
  });
};

const retrieveOrganizations = async (
  pageParam: string | undefined,
  limit?: number,
  includeRoot?: boolean
): Promise<GetAllOrganizationsResponse> => {
  const params = new URLSearchParams();
  if (pageParam) {
    params.set("cursor", pageParam);
  }
  if (limit) {
    params.set("limit", `${limit}`);
  }
  if (includeRoot) {
    params.set("includeRoot", "true");
  }
  const response = await axios.request({
    method: "GET",
    url: "/api/organization",
    params,
  });
  return response.data;
};

export const useAllOrganizationsQuery = (
  enabled: boolean = true,
  includeRoot: boolean = false,
  limit?: number
): UseInfiniteQueryResult<InfiniteData<GetAllOrganizationsResponse>> =>
  useInfiniteQuery({
    queryKey: organizationKeys.all,
    queryFn: ({ pageParam }: { pageParam?: string }) =>
      retrieveOrganizations(pageParam, limit, includeRoot),
    enabled,
    getNextPageParam: (lastPage) => lastPage.cursor,
    retry: 1,
    placeholderData: keepPreviousData,
    initialPageParam: undefined,
  });
