import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Button from "components/fundamentals/Button";
import { LoadingIcon } from "components/fundamentals/icons/LoadingIcon";
import { ImageUpload, PreviewFile } from "components/fundamentals/ImageUpload";
import { useEditEmployeeQuery } from "feature/user/queries";
import useNotification from "providers/NotificationProvider/useNotification";

interface Props {
  readonly id: string;
  readonly employeeId: string;
  readonly onSaveSuccess?: () => void;
  readonly onClose?: () => void;
}

export const EmployeeImageUpload = (props: Props) => {
  const [file, setFile] = useState<PreviewFile>();
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>();
  const { showErrorNotification, showSuccessNotification } = useNotification();

  const updateEmployee = useEditEmployeeQuery();

  const onSave = async () => {
    setSaving(true);
    updateEmployee
      .mutateAsync({
        employeeId: props.employeeId,
        modifiedKey: "profilePicture",
        modifiedValue: selectedImage,
      })
      .then(() => {
        showSuccessNotification("Image saved!");
        props.onSaveSuccess?.();
      })
      .catch(() => {
        showErrorNotification("Failed to save image");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <ImageUpload
        id={props.id}
        setSelectedImage={(image: string) => setSelectedImage(image)}
        file={file}
        setPreviewFile={(f: PreviewFile) => setFile(f)}
        label="Image"
      />
      <span className="ml-2 flex h-32 flex-col items-center justify-center">
        <Button isPrimary onClick={() => onSave()} disabled={saving || !selectedImage}>
          {saving ? (
            <LoadingIcon additionalStyleClasses="h-4 w-4" />
          ) : (
            <CheckIcon height={20} width={20} />
          )}
        </Button>
        <Button
          additionalStyleClasses="mt-2"
          onClick={() => {
            setSelectedImage(undefined);
            setSaving(false);
            setFile(undefined);
            if (props.onClose) {
              props.onClose();
            }
          }}
        >
          <XMarkIcon height={20} width={20} />
        </Button>
      </span>
    </div>
  );
};
