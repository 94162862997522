import { CheckIcon, PeopleIcon } from "@compoundfinance/design-system";
import { LoadingIcon } from "components/fundamentals/icons/LoadingIcon";
import AutocompleteSearch from "feature/forms/components/AutocompleteSearch";
import { TeamCore } from "feature/teams/models";
import { useCurrentUserTeamsQuery } from "feature/user/queries";
import classNames from "shared/utilities/classNames";

interface Props {
  readonly saving?: boolean;
  readonly team?: TeamCore | null;
  readonly setTeam: (selected?: TeamCore | null) => void;
}

export const TeamSelector = (props: Props) => {
  const { data: currentUserTeamsData } = useCurrentUserTeamsQuery();

  return (
    <div className="flex items-center space-x-2 max-w-[250px]" data-at="team-selector">
      <AutocompleteSearch
        selectedItem={props.team}
        setSelectedItem={props.setTeam}
        inputPlaceholder="All my teams"
        inputDataProperty="team-input"
        allItems={currentUserTeamsData ? currentUserTeamsData.items : []}
        renderSelectedItemText={(team) => team?.name ?? ""}
        renderItemOptionDisplay={(team: TeamCore, active: boolean, selected: boolean) => (
          <>
            <div className="ml-4 flex items-center space-x-2 min-w-[200px]">
              <PeopleIcon />
              <span className={classNames("truncate", selected ? "font-semibold" : "")}>
                {team.name}
              </span>
            </div>
            {selected && (
              <span
                className={classNames(
                  "absolute inset-y-0 right-0 flex items-center pr-4 w-full",
                  active ? "text-white" : "text-sky-600"
                )}
              >
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            )}
          </>
        )}
      />
      {props.saving && <LoadingIcon />}
    </div>
  );
};
