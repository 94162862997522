import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import { SlideOverProps, UserNavigationItem } from "../models";
import { OrganizationMenu } from "feature/organization/components/OrganizationMenu";
import { RepresentativeMenu } from "feature/representative/components/RepresentativeMenu";
import { SlideOver } from "feature/slideover";
import { CurrentUserAvatar } from "feature/user/components/CurrentUserAvatar";
import { useCurrentUserQuery } from "feature/user/queries";
import classNames from "shared/utilities/classNames";

const getCustomSlideover = (slideOverProps: SlideOverProps, closeFunction: () => void) => {
  if (slideOverProps.type === "organization") {
    return (
      <OrganizationMenu
        orgId={slideOverProps.organizationId}
        hasEditPermissions={slideOverProps.hasEditPermissions}
        closeMenu={closeFunction}
      />
    );
  }
  if (slideOverProps.type === "representative") {
    return (
      <RepresentativeMenu
        hasEditPermissions={slideOverProps.hasEditPermissions}
        closeMenu={closeFunction}
        employeeId={slideOverProps.employeeId}
      />
    );
  }
  if (slideOverProps.type === "user") {
    return <div>Not implemented</div>;
  }

  const exhaustiveCheck: never = slideOverProps;
  throw new Error(exhaustiveCheck);
};

interface DataProps {
  readonly userNavigationItems: UserNavigationItem[];
}

const NavigationBarProfileDropdown = (props: DataProps) => {
  // Whether or not the slideover is open
  const [slideOverOpen, setSlideOverOpen] = useState<boolean>(false);
  // Props used to render the slideover
  const [slideOverProps, setSlideOverProps] = useState<SlideOverProps | undefined>(undefined);

  const { userNavigationItems } = props;
  const { data } = useCurrentUserQuery();
  const router = useRouter();

  return (
    <>
      <Menu as="div" className="relative z-20 ml-3 mr-2">
        {({ open }) => (
          <div
            className={classNames(
              "rounded-xl",
              "[&:has(button:focus-visible)]:ring-4 [&:has(button:focus-visible)]:ring-offset-1 [&:has(button:focus-visible)]:ring-blue-200"
            )}
          >
            <Menu.Button
              className={classNames(
                "flex flex-row items-center gap-2 p-1 cursor-pointer rounded-xl",
                "focus-visible:ring-indigo-500 focus-visible:ring-2",
                "focus-visible:outline-none",
                "hover:bg-gray-100",
                open ? "bg-gray-100" : undefined
              )}
            >
              <div data-testid="profile-name" className="text-sm font-medium pl-1.5 text-gray-800">
                {data?.user.name}
              </div>
              <div className="text-sm">
                <span className="sr-only">Open user menu</span>
                <div>
                  <CurrentUserAvatar testLocator="profile-image" />
                </div>
              </div>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {userNavigationItems.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) =>
                      item.slideOverProps ? (
                        <button
                          type="button"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                          )}
                          data-at={item.name.toLowerCase().replaceAll(" ", "")}
                          onClick={() => {
                            setSlideOverProps(item.slideOverProps);
                            setSlideOverOpen(true);
                          }}
                        >
                          {item.name}
                        </button>
                      ) : (
                        <a
                          href={item.href}
                          className={classNames(
                            active ? "bg-gray-800" : "",
                            "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          )}
                        >
                          {item.name}
                        </a>
                      )
                    }
                  </Menu.Item>
                ))}
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={async () =>
                        axios.get("/api/auth/logout").catch(async () => {
                          await router.push("/", undefined, { shallow: true });
                          router.reload();
                        })
                      }
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                      )}
                    >
                      Sign Out
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
      {/* This needs to live outside of the `Menu` especially if we want it to be interactive */}
      {slideOverProps && (
        <SlideOver open={slideOverOpen} setClose={() => setSlideOverOpen(false)}>
          {getCustomSlideover(slideOverProps, () => setSlideOverOpen(false))}
        </SlideOver>
      )}
    </>
  );
};

export default NavigationBarProfileDropdown;
