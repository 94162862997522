import { ReactElement } from "react";
import classNames from "shared/utilities/classNames";

const Badge = ({
  text,
  children,
  additionalStyleClasses,
}: {
  text?: string | null;
  children?: ReactElement;
  additionalStyleClasses?: string;
}) => (
  <span
    className={classNames(
      "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium text-gray-700",
      additionalStyleClasses ?? ""
    )}
  >
    {text}
    {children}
  </span>
);

export default Badge;
