import classNames from "shared/utilities/classNames";

interface Props {
  readonly color: "blue" | "red";
  readonly position: "top" | "bottom";
  readonly size?: "small" | "base";
}

export const FlashingNotificationDot = (props: Props) => {
  return (
    <span
      className={classNames(
        "absolute right-0 -mr-1 flex",
        props.size === "small" ? "h-2 w-2" : "h-3 w-3",
        props.position === "top" ? "top-0 -mt-1" : "bottom-0 -mb-1"
      )}
    >
      <span
        className={classNames(
          "absolute inline-flex h-full w-full animate-ping rounded-full opacity-75",
          props.color === "blue" ? "bg-sky-400" : "bg-red-400"
        )}
      ></span>
      <span
        className={classNames(
          "relative inline-flex rounded-full",
          props.size === "small" ? "h-2 w-2" : "h-3 w-3",
          props.color === "blue" ? " bg-sky-500" : "bg-red-500"
        )}
      ></span>
    </span>
  );
};
