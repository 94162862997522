/* eslint-disable max-len */
import { Disclosure } from "@headlessui/react";
import axios from "axios";
import { useRouter } from "next/router";
import { NavigationItem } from "../models";
import { CurrentUserAvatar } from "feature/user/components/CurrentUserAvatar";
import { useCurrentUserQuery } from "feature/user/queries";
import classNames from "shared/utilities/classNames";

interface DataProps {
  readonly navigationItems: NavigationItem[];
}

const NavigationBarMobileMenu = (props: DataProps) => {
  const router = useRouter();
  const { navigationItems } = props;

  const { data: currentUserData, status: currentUserQueryStatus } = useCurrentUserQuery();

  return (
    <Disclosure.Panel className="md:hidden">
      <div className="space-y-1 pt-2 pb-3">
        {navigationItems.map((item) => {
          const current = router.asPath === item.href;
          return (
            item.enabled && (
              <Disclosure.Button
                key={item.name}
                as="a"
                href={item.href}
                className={classNames(
                  current
                    ? "border-sky-500 bg-sky-50 text-sky-700"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700",
                  "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                )}
                aria-current={current ? "page" : undefined}
              >
                {item.name}
              </Disclosure.Button>
            )
          );
        })}
      </div>
      {currentUserQueryStatus === "success" ? (
        <div className="border-t border-gray-200 pt-4 pb-3">
          <div className="flex items-center px-4">
            <div className="relative h-10 w-10 flex-shrink-0">
              <CurrentUserAvatar />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium text-gray-800">{currentUserData.user.name}</div>
            </div>
          </div>
          {/* TODO iterate over user navigation elements */}
          <div className="mt-3 space-y-1">
            <button
              onClick={async () =>
                axios.get("/api/auth/logout").catch(async () => {
                  await router.push("/", undefined, { shallow: true });
                  router.reload();
                })
              }
              className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
            >
              Sign out
            </button>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </Disclosure.Panel>
  );
};

export default NavigationBarMobileMenu;
