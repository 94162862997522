import { ExternalIcon } from "@compoundfinance/design-system";
import { useState } from "react";
import NavigationBarItem, { ADVISOR_HELP_NAV_ITEM } from "./NavigationBarItem";
import { NavigationItem, NavigationRoutes } from "../models";
import { AdvisorHQNotificationResponse } from "feature/notifications/models";
import { useAdvisorHQNotificationCountQuery } from "feature/notifications/queries";

const getSectionNotificationCount = (
  route: NavigationRoutes | string,
  notificationCounts?: AdvisorHQNotificationResponse
): number | undefined => {
  if (route === "/advisorhq") {
    return notificationCounts?.advisorHQNotificationCount;
  } else if (route === "/admin/accounts-attention") {
    return notificationCounts?.opsAccountActionsCount;
  }
  return undefined;
};

interface Props {
  readonly navigation: NavigationItem[] | undefined;
  readonly sideBarOpen: boolean;
  readonly activeNav?: NavigationRoutes;
}

const SideBarNavigation = (props: Props) => {
  const [hoverSideBar, setHoverSideBar] = useState(false);
  /**
   * To prevent this request from firing on all pages, we probably want to either:
   * - It would be ideal if we could add a `getServerSideProps` function to our custom _app.tsx file, but it's not supported. https://nextjs.org/docs/advanced-features/custom-app
   *   - Their recommendation is to migrate the the app/ pattern.
   * - Otherwise a quicker solution might be to use the ReactQuery persistentQueryClient - https://tanstack.com/query/v4/docs/plugins/persistQueryClient
   *   - This would also be beneficial for other API calls that we want to keep in the cache across different pages.
   */
  const { data } = useAdvisorHQNotificationCountQuery();
  return (
    <div className="hidden md:block z-20 border-r border-b border-gray-200 bg-white h-full">
      <nav
        className="h-screen-wo-nav absolute"
        aria-label="Sidebar"
        onMouseEnter={() => {
          setHoverSideBar(true);
        }}
        onMouseLeave={() => {
          setHoverSideBar(false);
        }}
      >
        <div className="flex flex-col h-full border-b border-r bg-white justify-between pb-3">
          <div className="py-5 h-full">
            {props.navigation &&
              props.navigation
                .filter((item) => item.enabled)
                .map((item) => (
                  <NavigationBarItem
                    navigationItem={item}
                    key={item.name}
                    notificationCount={getSectionNotificationCount(item.href, data)}
                    activeNav={props.activeNav}
                    expanded={hoverSideBar || props.sideBarOpen}
                  />
                ))}
          </div>
          <NavigationBarItem
            navigationItem={ADVISOR_HELP_NAV_ITEM}
            key={ADVISOR_HELP_NAV_ITEM.name}
            activeNav={props.activeNav}
            expanded={hoverSideBar || props.sideBarOpen}
            suffix={<ExternalIcon />}
          />
        </div>
      </nav>
    </div>
  );
};

export default SideBarNavigation;
