import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import Link from "next/link";

interface Props {
  readonly href: string;
  readonly label: string;
  readonly dataAt?: string;
}

export const BackToPrevious = (props: Props) => (
  <Link
    href={props.href}
    passHref
    className="group mt-1 flex w-fit cursor-pointer items-center text-sm text-sky-600"
    data-back-to={props.dataAt}
  >
    <ArrowLeftCircleIcon className="mr-1.5 h-5 w-5" />
    <div className="opacity-0 transition-opacity duration-300 group-hover:opacity-100">
      {props.label}
    </div>
  </Link>
);
