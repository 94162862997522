import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Duration } from "luxon";
import Error from "next/error";
import { AdvisorHQNotificationResponse } from "./models";

export const advisorHQNotificationCountQueryKey = ["advisorHQNotificationCount"];

export const useAdvisorHQNotificationCountQuery = () => {
  return useQuery<AdvisorHQNotificationResponse, Error>({
    queryKey: advisorHQNotificationCountQueryKey,
    queryFn: () => axios.get("/api/notifications/advisorhq").then((res) => res.data),
    retry: 1,
    staleTime: Duration.fromDurationLike({ minutes: 10 }).as("milliseconds"),
  });
};
