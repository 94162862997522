import { Button, Tooltip } from "@compoundfinance/design-system";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import React from "react";
import { MailboxType } from "clients/generated/prisma";

interface Props {
  readonly isResync: boolean;
  readonly mailboxType: MailboxType;
  readonly onBehalfOf?: string; // employee id of the user we are syncing on behalf of
}

export const EmailSyncButton = (props: Props): React.ReactElement => (
  <div className="flex items-center" data-at="auth-email-button">
    <Link
      href={`/nylas/auth?mailboxType=${props.mailboxType}&onBehalfOf=${props.onBehalfOf ?? ""}`}
    >
      <Button variant="internal" className="mr-4">
        {props.isResync
          ? `Resync ${props.mailboxType} Email Integration`
          : `Setup ${props.mailboxType} Email Integration`}
      </Button>
    </Link>
    {props.isResync && (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <QuestionMarkCircleIcon height={18} color="#6b7280" />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content>
            <span>
              If you are having issues with your emails showing in AdvisorHQ, support may ask you to
              use this button
            </span>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip>
    )}
  </div>
);
