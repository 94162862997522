import classNames from "shared/utilities/classNames";

type IconType = "LIGHT" | "DARK";

interface Props {
  type?: IconType;
  additionalStyleClasses?: string;
  sizeClasses?: string;
}

export const LoadingIcon = (props: Props) => (
  <svg
    role="progressbar"
    aria-live="polite"
    aria-valuetext="Loading..."
    className={classNames(
      props.sizeClasses ?? "h-6 w-6",
      "mr-2 animate-spin rounded-full border-2 border-transparent border-opacity-50 bg-transparent",
      props.type === "LIGHT"
        ? "border-r-white border-t-white"
        : "border-r-gray-500 border-t-gray-500",
      props.additionalStyleClasses || ""
    )}
    viewBox="0 0 40 40"
  />
);
