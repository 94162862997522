function classNames(...classes: (string | undefined | false)[]): string {
  return classes.filter(Boolean).join(" ");
}

export function groupClassNamesByRule(classNamesGroupedByRule: Record<string, string[]>) {
  return Object.entries(classNamesGroupedByRule)
    .flatMap(([key, values]) => values.map((value) => `${key}:${value}`))
    .join(" ");
}

export default classNames;
