import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";
import { EditableDetails } from "./EditableDetails";
import { EmailSyncButton } from "./EmailSyncButton";
import { MailboxType } from "clients/generated/prisma";
import { useEmployeeQuery } from "feature/user/queries";

interface Props {
  readonly employeeId: string;
  readonly hasEditPermissions: boolean;
  readonly closeMenu: () => void;
}

export const RepresentativeMenu = (props: Props): React.ReactElement => {
  const { data: employeeQueryData } = useEmployeeQuery(props.employeeId, true);
  const showResyncEmailButton =
    employeeQueryData?.type === "employee" && employeeQueryData.user.hasNylasGrant;
  return (
    <>
      <div className="py-6" data-at="orgId">
        <div className="px-4" data-at="organization-menu">
          <div className="flex items-center justify-between">
            <Dialog.Title className="ml-2 text-lg font-medium text-gray-900">
              Advisor Details
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                onClick={props.closeMenu}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4">
          <EditableDetails
            employeeId={props.employeeId}
            hasEditPermissions={props.hasEditPermissions}
            isAdminPage={false}
          />
          {showResyncEmailButton && (
            <EmailSyncButton isResync={true} mailboxType={MailboxType.Primary} />
          )}
        </div>
      </div>
    </>
  );
};
