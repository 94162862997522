import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { useState } from "react";
import { UpdateOrganizationRequest, UpdateOrganizationResponse } from "../models";
import { organizationKeys } from "../queries";
import Button from "components/fundamentals/Button";
import { LoadingIcon } from "components/fundamentals/icons/LoadingIcon";
import { ImageUpload, PreviewFile } from "components/fundamentals/ImageUpload";

interface Props {
  readonly id: string;
  readonly orgId: string;
  readonly onBeforeSave?: () => void;
  readonly onSaveSuccess?: () => void;
  readonly onSaveError?: () => void;
  readonly onSaveFinally?: () => void;
  readonly onClose?: () => void;
}

// Used in the Organization Menu slide over and also on the proposal page
export const OrganizationImageUpload = (props: Props) => {
  const [file, setFile] = useState<PreviewFile>();
  const [savingImage, setSavingImage] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>();
  const queryClient = useQueryClient();

  const saveImage = () => {
    if (props.onBeforeSave) {
      props.onBeforeSave();
    }
    setSavingImage(true);
    axios
      .request<any, AxiosResponse<UpdateOrganizationResponse>, UpdateOrganizationRequest>({
        url: `/api/organization/${props.orgId}`,
        method: "PUT",
        data: { logo: selectedImage },
      })
      .then(async (response: AxiosResponse<UpdateOrganizationResponse>) => {
        if (response.status >= 400) {
          if (props.onSaveError) {
            props.onSaveError();
          }
        } else {
          // Clear query cache. Retrieve the updated organization
          // Ideally we could use the React-Query Mutations, but we need to wait for the image to be upload and ready to use.
          await queryClient.invalidateQueries({
            queryKey: organizationKeys.id(props.orgId),
          });

          if (props.onSaveSuccess) {
            props.onSaveSuccess();
          }
        }
      })
      .catch(() => {
        if (props.onSaveError) {
          props.onSaveError();
        }
      })
      .finally(() => {
        setSavingImage(false);
        if (props.onSaveFinally) {
          props.onSaveFinally();
        }
      });
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <ImageUpload
        id={props.id}
        setSelectedImage={(image: string) => setSelectedImage(image)}
        file={file}
        setPreviewFile={(f: PreviewFile) => setFile(f)}
        label="Firm Logo"
      />
      <span className="ml-2 flex h-32 flex-col items-center justify-center">
        <Button isPrimary onClick={() => saveImage()} disabled={savingImage || !selectedImage}>
          {savingImage ? (
            <LoadingIcon additionalStyleClasses="h-4 w-4" />
          ) : (
            <CheckIcon height={20} width={20} />
          )}
        </Button>
        <Button
          additionalStyleClasses="mt-2"
          onClick={() => {
            setSelectedImage(undefined);
            setSavingImage(false);
            setFile(undefined);
            if (props.onClose) {
              props.onClose();
            }
          }}
        >
          <XMarkIcon height={20} width={20} />
        </Button>
      </span>
    </div>
  );
};
