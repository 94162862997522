import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UploadDocumentRequest } from "./validation";

export const useDocumentMutation = () => {
  return useMutation({
    mutationFn: (formData: UploadDocumentRequest) =>
      axios.request({
        url: `/api/document`,
        method: "POST",
        data: formData,
      }),
  });
};

export const useDeleteDocumentMutation = () => {
  return useMutation({
    mutationFn: (documentId: string) =>
      axios.request({
        url: `/api/document/${documentId}`,
        method: "DELETE",
      }),
  });
};
