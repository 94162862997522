import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { EmployeeImageRow } from "./EmployeeImageRow";
import { Tooltip } from "components/fundamentals/Tooltip";
import { PdfUploader } from "feature/documents/components/PdfUploader";
import { getDocumentUrl } from "feature/documents/models";
import { useDocumentMutation } from "feature/documents/queries";
import { ModifiableRow } from "feature/organization/components/menu/ModifiableRow";
import { employeeKeys, useEditEmployeeQuery, useEmployeeQuery } from "feature/user/queries";
import { ErrorResponse } from "lib/api/shared/errors";
import useNotification from "providers/NotificationProvider/useNotification";

interface Props {
  readonly employeeId: string;
  readonly hasEditPermissions: boolean;
  readonly isAdminPage: boolean;
}
export const EditableDetails = (props: Props) => {
  const [, setSaving] = useState<boolean>(false);
  const [uploadingForm, setUploadingForm] = useState<boolean>(false);

  const { showErrorNotification, showSuccessNotification } = useNotification();

  const { data: employeeQueryData } = useEmployeeQuery(props.employeeId, true);
  const updateEmployee = useEditEmployeeQuery();
  const queryClient = useQueryClient();

  const onSave = async (modifiedKey: string, modifiedValue: string, successFailureKey: string) => {
    setSaving(true);
    updateEmployee
      .mutateAsync({ employeeId: props.employeeId, modifiedKey, modifiedValue })
      .then(() => {
        showSuccessNotification(`${successFailureKey} saved!`);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        const firstMessage = error.response?.data.errors?.fieldErrors[modifiedKey]?.[0];
        showErrorNotification(firstMessage ?? `Failed to save ${successFailureKey}`);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const currentAdv2bDoc = employeeQueryData?.user.documents?.find(
    (doc) => doc.documentType === "ADV2B"
  );
  const documentMutation = useDocumentMutation();

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:divide-y sm:divide-gray-200 sm:p-0">
      {employeeQueryData && employeeQueryData.type === "employee" && (
        <>
          {props.isAdminPage && (
            <EmployeeImageRow
              employeeId={employeeQueryData.user.id}
              employeeName={employeeQueryData.user.name}
              image={employeeQueryData.user.avatar}
              hasEditPermissions={props.hasEditPermissions}
            />
          )}

          <ModifiableRow
            rowId="repName"
            rowLabel="Name"
            rowValue={employeeQueryData.user.name}
            hasEditPermissions={props.hasEditPermissions}
            onSaveValue={async (modifiedValue: string) => {
              await onSave("name", modifiedValue, "Name");
            }}
          />

          <ModifiableRow
            rowId="repPhoneNum"
            rowLabel="Phone"
            rowValue={employeeQueryData.user.phoneNumber ?? ""}
            hasEditPermissions={props.hasEditPermissions}
            onSaveValue={async (modifiedValue: string) => {
              await onSave("phoneNumber", modifiedValue, "Phone Number");
            }}
          />

          <ModifiableRow
            rowId="repCrdNum"
            rowLabel="CRD Number"
            rowValue={employeeQueryData.user.crdNumber ?? ""}
            hasEditPermissions={props.hasEditPermissions}
            onSaveValue={async (modifiedValue: string) => {
              await onSave("crdNumber", modifiedValue, "CRD Number");
            }}
          />
          <ModifiableRow
            rowId="repGNum"
            rowLabel="G Number"
            rowValue={employeeQueryData.user.gNumber ?? ""}
            hasEditPermissions={props.hasEditPermissions && props.isAdminPage}
            onSaveValue={async (modifiedValue: string) => {
              await onSave("gNumber", modifiedValue, "G Number");
            }}
            tooltipContent={
              <div className="space-y-2">
                <p>
                  A unique firm and/or advisor identifier in Fidelity for which customer accounts
                  are viewable under.
                </p>
                <p>It must be exactly 8 digits and numeric.</p>
              </div>
            }
          />
          <ModifiableRow
            rowId="repSLMasterNum"
            rowLabel="SL Master"
            rowValue={employeeQueryData.user.slMasterNumber ?? ""}
            hasEditPermissions={props.hasEditPermissions && props.isAdminPage}
            onSaveValue={async (modifiedValue: string) => {
              await onSave("slMasterNumber", modifiedValue, "SL Master Number");
            }}
            tooltipContent={
              <div className="space-y-2">
                <p>
                  A unique firm and/or advisor identifier in Charles Schwab for which customer
                  accounts are viewable under.
                </p>
                <p>It must be exactly 8 digits and numeric.</p>
              </div>
            }
          />

          <ModifiableRow
            rowId="clientSupportEmail"
            rowLabel="Support Email"
            rowValue={employeeQueryData.user.preferences?.clientSupportEmail ?? ""}
            hasEditPermissions={props.hasEditPermissions}
            onSaveValue={async (modifiedValue: string) => {
              await onSave("clientSupportEmail", modifiedValue, "Client Support Email");
            }}
            tooltipContent={
              <div className="space-y-2">
                <p> A support email can be used for client transactional email communications.</p>
                <p>
                  If specified, this will be used as the email reply-to instead of your login email.
                </p>
              </div>
            }
          />

          <ModifiableRow
            rowId="priorFirmName"
            rowLabel="Prior Advisory Firm Name"
            rowValue={employeeQueryData.user.priorFirmName ?? ""}
            hasEditPermissions={props.hasEditPermissions}
            onSaveValue={async (modifiedValue: string) => {
              await onSave("priorFirm", modifiedValue, "Prior Firm Name");
            }}
          />
          <div className="items-left flex flex-col pt-5">
            {props.isAdminPage && (
              <div className="flex items-center">
                <PdfUploader
                  onLoaded={async (file: string) => {
                    setUploadingForm(true);
                    await documentMutation
                      .mutateAsync({
                        document: file,
                        name: "ADV2B",
                        documentType: "ADV2B",
                        employeeId: props.employeeId,
                      })
                      .then(async () => {
                        await queryClient.invalidateQueries({
                          queryKey: employeeKeys.id(props.employeeId),
                        });
                        showSuccessNotification("Successfully uploaded document!");
                      })
                      .catch(() => {
                        showErrorNotification("Error uploading document");
                      })
                      .finally(() => {
                        setUploadingForm(false);
                      });
                  }}
                  buttonText="Upload New ADV2B"
                  hideDocumentNameAfterUpload
                  submitting={uploadingForm}
                />
                <Tooltip
                  content={
                    <div className="space-y-2">
                      <p>
                        Your ADV Part 2B will be automatically shared when appropriate to satisfy
                        compliance requirements (e.g. when delivering client agreements for review
                        and signing).
                      </p>
                    </div>
                  }
                  justification="left"
                >
                  <QuestionMarkCircleIcon width={20} height={20} color="gray-500" />
                </Tooltip>
              </div>
            )}
            {currentAdv2bDoc && props.isAdminPage && (
              <span className="mt-3 text-xs">
                <a
                  href={getDocumentUrl(currentAdv2bDoc, { type: "public" })}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  View Current ADV2B
                </a>
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};
