import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import classNames from "shared/utilities/classNames";

type Width = "small" | "medium" | "large";

const calculateMaxWidthClass = (width?: Width) => {
  switch (width) {
    case "medium":
      return "max-w-2xl";
    case "large":
      return "max-w-4xl";
    case "small":
    default:
      return "max-w-md";
  }
};

interface Props {
  readonly open: boolean;
  readonly setClose: () => void;
  readonly children: React.ReactElement;
  readonly width?: Width;
}

export const SlideOver = (props: Props) => (
  <Transition.Root show={props.open} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 z-40 overflow-hidden" onClose={props.setClose}>
      <div className="absolute inset-0 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-y-0 right-0 flex max-w-full pl-16">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div
              className={classNames(
                "h-full w-screen overflow-y-scroll bg-white shadow-xl",
                calculateMaxWidthClass(props.width)
              )}
            >
              {props.children}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
