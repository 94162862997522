import path from "path";
import { ArrowUpTrayIcon } from "@heroicons/react/20/solid";
import { useRef, useState } from "react";
import Button from "components/fundamentals/Button";

interface Props {
  // Use this to set the loaded PDF data url so you can upload it to the server.
  readonly onLoaded: (result: string, fileName: string) => void;
  readonly buttonText?: string;
  readonly hideDocumentNameAfterUpload?: boolean;
  readonly submitting?: boolean;
}

export const PdfUploader = (props: Props) => {
  const [documentName, setDocumentName] = useState<String>();

  const uploadDocumentInput = useRef<HTMLInputElement>(null);

  const onDocumentSelected = async (file?: File) => {
    if (!file) {
      return;
    }
    // Remove the extension. Everything coming into this should be a pdf.
    const fileName = path.parse(file.name).name;
    const reader = new FileReader();
    reader.onloadend = async (e: ProgressEvent<FileReader>) => {
      if (e.target) {
        props.onLoaded(e.target.result as string, fileName);
      }
    };
    reader.readAsDataURL(file);
    setDocumentName(fileName);
  };

  return (
    <div className="flex items-center space-x-4">
      <Button
        onClick={() => uploadDocumentInput.current?.click()}
        size="medium"
        showLoadingIconOnSubmitting
        submitting={props.submitting}
      >
        <ArrowUpTrayIcon className="mr-2 h-3 w-3" /> {props.buttonText ?? "Upload PDF"}
      </Button>
      {!props.hideDocumentNameAfterUpload && (
        <div className="text-sm font-medium">{documentName}</div>
      )}
      <input
        type="file"
        hidden
        ref={uploadDocumentInput}
        onChange={async (event) => {
          if (!event.target.files) {
            return;
          }
          await onDocumentSelected(event.target.files[0]);
        }}
        accept="application/pdf"
      />
    </div>
  );
};
