import { DateTime } from "luxon";

export const sortByMostRecentDate = (a?: string, b?: string) => {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  return DateTime.fromISO(a) > DateTime.fromISO(b) ? -1 : 1;
};

export const sortBy = <T>(array: T[], key: string): T[] => {
  const keyParts = key.split(".");
  const getKey = (obj: any) => keyParts.reduce((acc, part) => acc?.[part], obj);
  return array.slice().sort((a, b) => {
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    const aVal = getKey(a);
    const bVal = getKey(b);
    if (typeof aVal === "string" && typeof bVal === "string") {
      return aVal.localeCompare(bVal);
    } else {
      return getKey(a) > getKey(b) ? 1 : -1;
    }
  });
};
