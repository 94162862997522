import { useRouter } from "next/router";
import { useState } from "react";
import { TeamSelector } from "./TeamSelector";
import { ALL_MY_TEAMS, SelectedTeams, TeamCore } from "feature/teams/models";
import { useCurrentUserQuery, useUpdateEmployeePreferences } from "feature/user/queries";
import useNotification from "providers/NotificationProvider/useNotification";

interface Props {
  readonly selectedTeams: SelectedTeams;
  readonly setSelectedTeams: (selected: SelectedTeams) => void;
}

export const AppTeamSelector = (props: Props) => {
  const { showErrorNotification } = useNotification();
  const router = useRouter();

  const { data: currentUserData } = useCurrentUserQuery();

  const [saving, setSaving] = useState<boolean>(false);
  const preferenceMutation = useUpdateEmployeePreferences();

  const onSave = async (value?: TeamCore | null) => {
    if (currentUserData) {
      setSaving(true);
      const selectedTeam: SelectedTeams = !value
        ? { type: "all" }
        : { type: "individual", team: value };

      await preferenceMutation
        .mutateAsync({
          employeeId: currentUserData.user.id,
          selectedTeam: !value ? null : value.id,
        })
        .then(() => {
          props.setSelectedTeams(selectedTeam);
          router.reload();
        })
        .catch(() => {
          showErrorNotification("Something went wrong saving the team preference", undefined, true);
          setSaving(false); // only in the catch. The router.reload will do a page refresh eventually
        });
    }
  };

  return (
    <TeamSelector
      saving={saving}
      team={props.selectedTeams.type === ALL_MY_TEAMS ? null : props.selectedTeams.team}
      setTeam={onSave}
    />
  );
};
