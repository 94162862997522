import { ReactNode } from "react";
import classNames from "shared/utilities/classNames";

interface Props {
  readonly pageTitle: string | ReactNode;
}

export const pageHeaderStyles =
  "text-3xl font-bold leading-7 tracking-tight text-alternativ-monochrome-dark";
const PageHeader = (props: Props) => (
  <header className="mb-8">
    <h1 className={classNames(pageHeaderStyles, "mb-8 ")}>{props.pageTitle}</h1>
  </header>
);

export default PageHeader;
