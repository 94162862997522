import { SelectOptionProps } from "../forms/components/FormSelect";
import { sortByMostRecentDate } from "shared/utilities/sort";

export const documentSort = (a: Document, b: Document) => {
  return sortByMostRecentDate(a.documentDate, b.documentDate);
};

export const documentTypes = [
  "Factsheet",
  "PPM",
  "Diligence",
  "Other",
  "SubscriptionDocument",
  "ADV2B",
  "ClientRiskDocumentation",
] as const;

export type DocumentType = (typeof documentTypes)[number];

export const documentTypesSelectOptions: SelectOptionProps<DocumentType>[] = [
  { value: "Other", label: "" },
  // Diligence could be added, currently we aren't doing anything special with it.
  // { value: "Diligence", label: "Diligence" },
  { value: "Factsheet", label: "Factsheet" },
  { value: "PPM", label: "PPM" },
  { value: "SubscriptionDocument", label: "Subscription Document" },
];

export type DocumentAssociation = "public" | "client" | "opportunity";
export interface Document {
  readonly createDate: string;
  readonly documentDate?: string;
  readonly documentType?: DocumentType;
  readonly documentUrl?: string;
  readonly id: string;
  readonly name: string;
}

interface ClientDocumentType {
  readonly type: "client";
  readonly clientId: string;
}

interface PublicDocumentType {
  readonly type: "public";
}

interface OpportunityDocumentType {
  readonly type: "opportunity";
}

interface ModelFactheetDocumentType {
  readonly type: "model-factsheet";
}

export type DocumentUrlType =
  | ClientDocumentType
  | PublicDocumentType
  | OpportunityDocumentType
  | ModelFactheetDocumentType;

export const getDocumentUrl = (document: Document, type: DocumentUrlType): string | undefined => {
  if (!document.documentUrl) {
    return undefined;
  }

  switch (type.type) {
    case "client":
      return `/api/client/${type.clientId}/document/${document.id}?download=true`;
    case "public":
      return `/api/public/document/${document.id}/${document.name}.pdf?download=true`;
    case "opportunity":
      return `/api/document/${document.id}/${document.name}.pdf?download=true`;
    case "model-factsheet":
      return `/api/document/${document.id}/${document.name}.pdf?download=true`;
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(exhaustiveCheck);
    }
  }
};
