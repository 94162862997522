import Head from "next/head";
import { ReactNode, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { PageErrorBoundary } from "./error/PageErrorBoundary";
import PageContainer from "./fundamentals/page/PageContainer";
import { BaseNavigation, NavigationBar } from "../feature/navigation/components/NavigationBar";
import SideBarNavigation from "../feature/navigation/components/SideBarNavigation";
import { NavigationElements, NavigationRoutes } from "../feature/navigation/models";
import { ReactQueryUtility } from "../feature/operational-tooling/ReactQueryUtility";
import { useCurrentUserQuery } from "../feature/user/queries";
import { AppContextProvider, defaultAppContext } from "../providers/AppContextProvider";
import { SelectedTeams } from "feature/teams/models";

interface DataProps {
  readonly children?: ReactNode;
  readonly navigation?: NavigationElements;
  readonly browserTitle?: string;
  readonly pageTitle?: string | ReactNode;
  readonly pageIdentifier?: string;
  readonly backToPreviousLink?: { href: string; label: string };
  readonly activeNav?: NavigationRoutes;
}

export const Layout = (props: DataProps) => {
  const { children, browserTitle, navigation } = props;
  // We should prefer the client-side selected value for Teams over the server-side provided value.
  const [selectedTeams, setSelectedTeams] = useState<SelectedTeams>(
    props.navigation?.appContext.selectedTeams ?? { type: "all" }
  );

  const [sideBarOpen, setSideBarOpen] = useState(navigation?.sideBarOpen ?? false);

  const { data: currentUserData } = useCurrentUserQuery();

  return (
    // It would be cool to pull this up to the `_app.tsx` layer but it's located here because most real pages in the app will populate the `navigation` which contains the required context.
    // I think we can make this better when we migrate the layout to the NextJS App directory.
    <AppContextProvider
      appContext={
        props.navigation ? { ...props.navigation.appContext, selectedTeams } : defaultAppContext
      }
    >
      <Head>
        <title>{browserTitle ? `${browserTitle} | CompoundHQ` : "CompoundHQ"}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <div className="flex flex-col" data-page={props.pageIdentifier}>
        {navigation ? (
          <NavigationBar
            navigation={navigation.navigationItems}
            userNavigation={navigation.userNavigationItems}
            sideBarToggleClicked={() => setSideBarOpen(!sideBarOpen)}
            backToPreviousLink={props.backToPreviousLink}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
          />
        ) : (
          <BaseNavigation />
        )}
        <div className="flex max-h-screen-wo-nav w-screen overflow-y-auto">
          {currentUserData?.type === "employee" && (
            <SideBarNavigation
              navigation={navigation?.navigationItems}
              sideBarOpen={sideBarOpen}
              activeNav={props.activeNav}
            />
          )}
          <PageContainer title={props.pageTitle}>
            <ErrorBoundary FallbackComponent={PageErrorBoundary}>{children}</ErrorBoundary>
          </PageContainer>
        </div>
      </div>
      <ReactQueryUtility />
    </AppContextProvider>
  );
};

export default Layout;
