import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const NavigationBarMobileMenuButton = ({ open }: { open: boolean }) => (
  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-500 hover:text-gray-700 focus:outline-none">
    <span className="sr-only">Open main menu</span>
    {open ? (
      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
    ) : (
      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
    )}
  </Disclosure.Button>
);

export default NavigationBarMobileMenuButton;
