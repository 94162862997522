import { Popover, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useRef, useState } from "react";
import classNames from "shared/utilities/classNames";

interface Props {
  readonly children: React.ReactElement;
  readonly content: React.ReactElement;
  readonly size?: "small" | "medium" | "large"; // These were sort of arbitrarily chosen. Feel free to modify accordingly
  readonly justification?: "left" | "center" | "right"; // defaults to center
  readonly orientation?: "top" | "bottom"; // i.e. on the top or bottom of the question mark icon (default is bottom)
  readonly className?: string;
}

export const Tooltip = (props: Props) => (
  <Popover className="relative flex items-center justify-center">
    {({ open }) => (
      <>
        <Popover.Button
          className={`
            ${open ? "" : "text-opacity-90"}
            group inline-flex items-center rounded-md px-2 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          {props.children}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          {/* We could possibly pull out some of the styling and require the consumer of this component to style the popover accordingly */}
          <Popover.Panel
            className={classNames(
              "absolute z-50 mt-2 rounded-md border bg-white shadow-lg sm:px-0 lg:max-w-3xl",
              // eslint-disable-next-line no-nested-ternary
              props.justification === "left"
                ? "translate-x-1/4 transform"
                : props.justification === "right"
                  ? "-translate-x-full transform"
                  : "left-1/2 -translate-x-1/2 transform",
              // eslint-disable-next-line no-nested-ternary
              props.size === "large"
                ? "min-w-[640px]"
                : props.size === "medium"
                  ? "min-w-[480px]"
                  : "min-w-[320px]",
              props.orientation === "top" ? "transform-y-full absolute bottom-10 transform" : "",
              props.className
            )}
          >
            <div className="relative z-50 p-4" data-at="tooltip">
              {props.content}
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export const DefaultTooltipIcon = () => (
  <QuestionMarkCircleIcon className="w-4 h=4 stroke-compound-gray-300 hover:stroke-compound-gray-500 fill-white" />
);

interface HoverProps {
  readonly children: React.ReactElement;
  readonly content: React.ReactElement;
  readonly size?: "small" | "medium" | "large"; // These were sort of arbitrarily chosen. Feel free to modify accordingly
  readonly additionalStyles?: string;
}

// Much of this was taken from the code-sandbox linked here: https://stackoverflow.com/questions/74769929/how-to-open-popover-manually-headless-ui
export const HoverTooltip = (props: HoverProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openState, setOpenState] = useState(false);

  const toggleMenu = () => {
    // toggle open state
    setOpenState(!openState);
    // toggle the menu by clicking on buttonRef
    buttonRef.current?.click();
  };

  const onHover = (open: boolean, action: "onMouseEnter" | "onMouseLeave") => {
    if (
      (!open && !openState && action === "onMouseEnter") ||
      (open && openState && action === "onMouseLeave")
    ) {
      toggleMenu();
    }
  };

  return (
    <Popover className="relative flex items-center justify-center">
      {({ open }) => (
        <div
          onMouseEnter={() => onHover(open, "onMouseEnter")}
          onMouseLeave={() => onHover(open, "onMouseLeave")}
          className="flex flex-col"
        >
          <Popover.Button
            ref={buttonRef}
            className={`
              ${open ? "" : "text-opacity-90"}
              group inline-flex items-center rounded-md px-2 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            {props.children}
          </Popover.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className={classNames(
                "absolute z-50 mt-2 rounded-md border bg-white shadow-lg sm:px-0 lg:max-w-3xl",
                props.additionalStyles ?? "",
                // eslint-disable-next-line no-nested-ternary
                props.size === "large"
                  ? "min-w-[640px]"
                  : props.size === "medium"
                    ? "min-w-[480px]"
                    : "min-w-[320px]"
              )}
            >
              <div className="relative z-50 p-4" data-at="tooltip">
                {props.content}
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};
