import { useRouter } from "next/router";
import { FallbackProps } from "react-error-boundary";
import Button from "../fundamentals/Button";

interface ErrorBoundaryContentProps {
  readonly showLogout?: boolean;
}

export const ErrorBoundaryContent = (props: ErrorBoundaryContentProps) => {
  const router = useRouter();
  return (
    <div className="flex justify-center">
      <div className="flex w-full max-w-[700px] flex-col items-center justify-center rounded-lg border border-alternativ-monochrome-light/50 bg-white py-5 px-4">
        <span>Uh oh. Something went wrong.</span>
        <div>We are working to fix the problem.</div>
        <div className="mt-8">You can also attempt to refresh your page and try again.</div>
        {props.showLogout && (
          <div className="mt-8 flex flex-col">
            <span>If you continue getting this error, please try to sign out and log back in.</span>
            <div className="flex justify-center">
              <Button onClick={() => router.push("/api/auth/logout")}>Sign Out</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type Props = FallbackProps & ErrorBoundaryContentProps;

export const PageErrorBoundary = (props: Props) => {
  return <ErrorBoundaryContent showLogout={props.showLogout} />;
};
