import { useCurrentUserQuery } from "../queries";
import { Avatar } from "components/fundamentals/Avatar";

interface DataProps {
  readonly testLocator?: string;
}

export const CurrentUserAvatar = (props: DataProps) => {
  const {
    data: currentUserData,
    status: currentUserQueryStatus,
    error: userError,
  } = useCurrentUserQuery();

  // If we have user data (current or stale), we will show it.
  if (currentUserData) {
    return (
      <Avatar
        avatar={currentUserData.user.avatar}
        name={currentUserData.user.name}
        testLocator={props.testLocator}
        height={32}
        width={32}
        iconBorderRadius="large"
      />
    );
  }

  if (currentUserQueryStatus === "error") {
    return <>{userError}</>;
  }

  return <div>Loading</div>;
};
