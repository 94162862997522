/* eslint-disable no-nested-ternary */
import { PencilIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { EmployeeImageUpload } from "./EmployeeImageUpload";
import { Avatar } from "components/fundamentals/Avatar";
import { boxyShadows } from "styles/shared";

interface Props {
  readonly employeeId: string;
  readonly employeeName: string;
  readonly image?: string;
  readonly hasEditPermissions: boolean;
}

export const EmployeeImageRow = (props: Props) => {
  const [editingImage, setEditingImage] = useState<boolean>(false);

  return (
    <div className="my-2 grid grid-cols-12 items-center py-2 px-2">
      <div className="col-span-3 text-sm font-medium text-gray-500">Picture</div>
      <div
        className="col-span-9 mt-1 flex h-32 w-full justify-between text-sm text-gray-900"
        data-at="employee-picture"
      >
        {props.image && !editingImage ? (
          <>
            <span className="relative flex h-[122px] w-[122px] items-center justify-center rounded-full border border-solid border-gray-200">
              <Avatar
                avatar={props.image}
                name={`Image for ${props.employeeName}`}
                width={120}
                height={120}
              />
            </span>

            {props.hasEditPermissions && (
              <span className="ml-2 flex h-32 items-center justify-end">
                <button type="button" onClick={() => setEditingImage(true)}>
                  <PencilIcon height={20} width={20} />
                </button>
              </span>
            )}
          </>
        ) : props.hasEditPermissions ? (
          <EmployeeImageUpload
            id="imageUpload"
            employeeId={props.employeeId}
            onSaveSuccess={() => {
              setEditingImage(false);
            }}
            onClose={() => setEditingImage(false)}
          />
        ) : (
          <div className={`${boxyShadows} flex h-full items-center justify-center`}>
            Work with the ops team to get an image uploaded.{" "}
            <a href="mailto:support@compoundplanning.com">support@compoundplanning.com</a>.
          </div>
        )}
      </div>
    </div>
  );
};
