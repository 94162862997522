/* eslint-disable react/jsx-props-no-spreading */
import Image from "next/image";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Button from "../Button";

export interface PreviewFile extends File {
  readonly preview: string;
}

interface DataProps {
  readonly id: string;
  readonly setSelectedImage: (selectedImage: string) => void;
  readonly file: PreviewFile | undefined;
  readonly setPreviewFile: (f: PreviewFile) => void;
  readonly label: string;
}

export const ImageUpload = (props: DataProps) => {
  const { setSelectedImage, setPreviewFile } = props;

  const onDrop = useCallback(
    ([acceptedFile]: File[]) => {
      if (!acceptedFile) {
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          setSelectedImage(e.target.result as string);
        }
      };
      reader.readAsDataURL(acceptedFile);

      setPreviewFile(
        Object.assign(acceptedFile, {
          preview: URL.createObjectURL(acceptedFile),
        })
      );
    },
    [setSelectedImage, setPreviewFile]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop,
  });

  return (
    <div
      {...getRootProps({
        className:
          "bg-gray-100 flex flex-col justify-center items-center w-full h-full max-w-[240px] rounded-md border border-dashed border-gray-300",
      })}
    >
      <input {...getInputProps()} />
      {props.file ? (
        <div
          className="border-1 box-border h-full w-full items-center justify-center border-gray-200"
          key={props.file.name}
        >
          <Image
            className="block h-full w-full"
            src={props.file.preview}
            alt={`${props.label} Preview`}
            width="200"
            height="120"
            // Revoke data uri after image is loaded
            onLoad={() => {
              if (props.file) {
                URL.revokeObjectURL(props.file.preview);
              }
            }}
          />
          <div className="pl-2 italic">* Image will be resized on save.</div>
        </div>
      ) : (
        <>
          <p className="mb-4">{`Upload ${props.label}`}</p>
          <Button onClick={open}>Open File Explorer</Button>
        </>
      )}
    </div>
  );
};
