/* eslint-disable no-nested-ternary */
import { PencilIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import { useState } from "react";
import { OrganizationImageUpload } from "../OrganizationImageUpload";
import nextFutureImageLoader from "shared/utilities/nextFutureImageLoader";
import { boxyShadows } from "styles/shared";

interface Props {
  readonly orgId: string;
  readonly orgName: string;
  readonly logo?: string;
  readonly hasEditPermissions: boolean;
  readonly setSuccessAlertProps?: () => void;
  readonly setErrorAlertProps?: () => void;
  readonly setShowAlert?: (showAlert: boolean) => void;
}

export const OrganizationLogoRow = (props: Props) => {
  const [editingImage, setEditingImage] = useState<boolean>(false);

  return (
    <div className="my-2 grid grid-cols-12 items-center py-2 px-2">
      <div className="col-span-3 text-sm font-medium text-gray-500">Logo</div>
      <div
        className="col-span-9 mt-1 flex h-32 w-full justify-between text-sm text-gray-900"
        data-at="org-logo"
      >
        {/* 
           If there is an org logo and we aren't editing, show the current image.
           If there was no previous logo or if we are editing, the we will show the uploader.
         */}
        {props.logo && !editingImage ? (
          <>
            <span className="relative flex h-[122px] w-[202px] items-center justify-center rounded-md border border-solid border-gray-200">
              <Image
                // Sizing the image to 200x120 and resizing down if the user provides a large image.
                // https://cloudinary.com/documentation/resizing_and_cropping#resize_and_crop_modes
                src={`/w_200,h_120,c_limit/${props.logo}`}
                loader={nextFutureImageLoader}
                alt={`Logo for ${props.orgName}`}
                fill
                sizes="200px"
                className="object-contain"
              />
            </span>

            {props.hasEditPermissions && (
              <span className="ml-2 flex h-32 items-center justify-end">
                <button type="button" onClick={() => setEditingImage(true)}>
                  <PencilIcon height={20} width={20} />
                </button>
              </span>
            )}
          </>
        ) : props.hasEditPermissions ? (
          <OrganizationImageUpload
            id="userNav"
            orgId={props.orgId}
            onBeforeSave={() => props.setShowAlert?.(false)}
            onSaveSuccess={() => {
              setEditingImage(false);
              props.setSuccessAlertProps?.();
              props.setShowAlert?.(true);
            }}
            onSaveError={() => {
              props.setErrorAlertProps?.();
              props.setShowAlert?.(true);
            }}
            onClose={() => setEditingImage(false)}
          />
        ) : (
          <div className={`${boxyShadows} flex h-full items-center justify-center`}>
            Work with your firm admin to get an image uploaded
          </div>
        )}
      </div>
    </div>
  );
};
