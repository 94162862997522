import { ReactNode } from "react";
import PageHeader from "./PageHeader";

interface Props {
  readonly title?: string | ReactNode;
  readonly children: ReactNode;
}

const PageContainer = (props: Props) => (
  <main className="mx-auto w-full p-4 md:ml-14 md:w-[calc(100%-3.5rem)] md:p-8">
    {props.title && <PageHeader pageTitle={props.title} />}
    {props.children}
  </main>
);

export default PageContainer;
