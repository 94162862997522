
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime,
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.18.0
 * Query Engine version: 4c784e32044a8a016d99474bd02a3b6123742169
 */
Prisma.prismaVersion = {
  client: "5.18.0",
  engine: "4c784e32044a8a016d99474bd02a3b6123742169"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`NotFoundError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.OpportunityScalarFieldEnum = {
  id: 'id',
  internal_id: 'internal_id',
  status: 'status',
  hold_reason: 'hold_reason',
  allocation_available: 'allocation_available',
  closing_type: 'closing_type',
  closing: 'closing',
  opening: 'opening',
  minimum: 'minimum',
  name: 'name',
  url: 'url',
  eligibility: 'eligibility',
  total_offering: 'total_offering',
  additional_closing_framework: 'additional_closing_framework',
  additional_offering_considerations: 'additional_offering_considerations',
  investment_type: 'investment_type',
  minimum_investment_considerations: 'minimum_investment_considerations',
  registration_type: 'registration_type',
  share_class: 'share_class',
  leverage: 'leverage',
  broker_dealer: 'broker_dealer',
  broker_dealer_name: 'broker_dealer_name',
  funds_committed: 'funds_committed',
  risk_category: 'risk_category',
  primary_asset_class: 'primary_asset_class',
  secondary_asset_class: 'secondary_asset_class',
  primary_objective: 'primary_objective',
  secondary_objective: 'secondary_objective',
  primary_geography: 'primary_geography',
  additional_characteristics: 'additional_characteristics',
  composition: 'composition',
  exit_options: 'exit_options',
  fund_duration_days: 'fund_duration_days',
  gics_sector: 'gics_sector',
  sector: 'sector',
  additional_disclosures: 'additional_disclosures',
  concentration_percentage: 'concentration_percentage',
  risk_notes: 'risk_notes',
  summary: 'summary',
  highlights: 'highlights',
  commission_location: 'commission_location',
  gross_commission: 'gross_commission',
  ubti_qualified: 'ubti_qualified',
  tax_reporting: 'tax_reporting',
  current_irr: 'current_irr',
  target_irr: 'target_irr',
  unit_price: 'unit_price',
  valuation_frequency: 'valuation_frequency',
  preferred_return_percentage: 'preferred_return_percentage',
  performance_expectation: 'performance_expectation',
  valuation_notes: 'valuation_notes',
  subscription_document_fund_id: 'subscription_document_fund_id',
  subdoc_file: 'subdoc_file',
  distribution_id: 'distribution_id',
  compliance_id: 'compliance_id',
  provider_id: 'provider_id',
  liquidity_id: 'liquidity_id',
  portfolio_manager_id: 'portfolio_manager_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.ValuationScalarFieldEnum = {
  id: 'id'
};

exports.Prisma.ComplianceScalarFieldEnum = {
  id: 'id',
  first_due_diligence: 'first_due_diligence',
  last_full_due_diligence: 'last_full_due_diligence',
  last_updated_due_diligence: 'last_updated_due_diligence',
  nda_required: 'nda_required',
  selling_agreement: 'selling_agreement',
  selling_agreement_signed: 'selling_agreement_signed',
  due_diligence_materials: 'due_diligence_materials',
  notes: 'notes',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.LiquidityScalarFieldEnum = {
  id: 'id',
  type: 'type',
  initial_lockup_days: 'initial_lockup_days',
  minimum_lockup_days: 'minimum_lockup_days',
  lockup_end: 'lockup_end',
  windows: 'windows',
  fee_percentage: 'fee_percentage',
  fee_dollars: 'fee_dollars',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.DistributionScalarFieldEnum = {
  id: 'id',
  actual_annual_rate: 'actual_annual_rate',
  target_annual_rate: 'target_annual_rate',
  target_quality: 'target_quality',
  target_timing: 'target_timing',
  curve: 'curve',
  compositions: 'compositions',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.DocumentScalarFieldEnum = {
  id: 'id',
  deleted_on: 'deleted_on',
  document_date: 'document_date',
  document_type: 'document_type',
  external_id: 'external_id',
  name: 'name',
  source: 'source',
  url: 'url',
  opportunity_id: 'opportunity_id',
  employee_id: 'employee_id',
  investment_model_id: 'investment_model_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.OrganizationScalarFieldEnum = {
  id: 'id',
  logo: 'logo',
  name: 'name',
  website_url: 'website_url',
  orion_id: 'orion_id',
  paying_employee_id: 'paying_employee_id',
  parent_organization_id: 'parent_organization_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.DocumentLinkScalarFieldEnum = {
  id: 'id',
  name: 'name',
  url: 'url',
  organization_id: 'organization_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AssetScalarFieldEnum = {
  id: 'id',
  units: 'units',
  unit_price: 'unit_price',
  as_of_date: 'as_of_date',
  orion_id: 'orion_id',
  last_sync: 'last_sync',
  cost_basis: 'cost_basis',
  orion_data: 'orion_data',
  account_id: 'account_id',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.InvestmentTransactionScalarFieldEnum = {
  id: 'id',
  name: 'name',
  date: 'date',
  amount: 'amount',
  quantity: 'quantity',
  price: 'price',
  orion_id: 'orion_id',
  sub_code: 'sub_code',
  notes: 'notes',
  type: 'type',
  account_id: 'account_id',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.PerformanceBreakdownScalarFieldEnum = {
  id: 'id',
  date: 'date',
  start_value: 'start_value',
  end_value: 'end_value',
  value_change: 'value_change',
  cash_flow_amount: 'cash_flow_amount',
  fee_amount: 'fee_amount',
  start_accrued_int: 'start_accrued_int',
  accrued_int_change: 'accrued_int_change',
  start_dividend: 'start_dividend',
  dividend_change: 'dividend_change',
  tax_value_change: 'tax_value_change',
  performance: 'performance',
  factor: 'factor',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on',
  account_id: 'account_id'
};

exports.Prisma.AssetSnapshotScalarFieldEnum = {
  id: 'id',
  as_of_date: 'as_of_date',
  units: 'units',
  unit_price: 'unit_price',
  cost_basis: 'cost_basis',
  orion_id: 'orion_id',
  account_id: 'account_id',
  asset_id: 'asset_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AlternativeAssetScalarFieldEnum = {
  id: 'id',
  committed_capital: 'committed_capital',
  contributed_capital: 'contributed_capital',
  orion_id: 'orion_id',
  as_of_date: 'as_of_date',
  fund_name: 'fund_name',
  current_value: 'current_value',
  class: 'class',
  account_id: 'account_id',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AccountScalarFieldEnum = {
  id: 'id',
  account_source: 'account_source',
  advisor_code: 'advisor_code',
  balance: 'balance',
  cash_reserve_amount: 'cash_reserve_amount',
  cash_reserve_percentage: 'cash_reserve_percentage',
  custodian: 'custodian',
  implementation: 'implementation',
  is_discretionary: 'is_discretionary',
  last_sync: 'last_sync',
  last_tradable_event: 'last_tradable_event',
  model_managed: 'model_managed',
  name: 'name',
  number: 'number',
  risk_score: 'risk_score',
  status: 'status',
  trade_notes: 'trade_notes',
  type: 'type',
  trading_blocked: 'trading_blocked',
  trading_blocked_reason: 'trading_blocked_reason',
  short_term_capital_gains_allowance: 'short_term_capital_gains_allowance',
  income_tax_rate: 'income_tax_rate',
  long_term_capital_gains_rate: 'long_term_capital_gains_rate',
  annual_capital_gains_budget: 'annual_capital_gains_budget',
  capital_gains_budget_updated_on: 'capital_gains_budget_updated_on',
  salesforce_account_id: 'salesforce_account_id',
  funded_date: 'funded_date',
  orion_id: 'orion_id',
  orion_registration_id: 'orion_registration_id',
  orion_status: 'orion_status',
  orion_data: 'orion_data',
  eclipse_id: 'eclipse_id',
  eclipse_portfolio_id: 'eclipse_portfolio_id',
  eclipse_cash_reserve_id: 'eclipse_cash_reserve_id',
  eclipse_traded: 'eclipse_traded',
  last_controls_export: 'last_controls_export',
  last_model_export: 'last_model_export',
  last_cash_export: 'last_cash_export',
  last_cash_rebalance: 'last_cash_rebalance',
  last_trade_generation_batch_id: 'last_trade_generation_batch_id',
  address_id: 'address_id',
  account_group_id: 'account_group_id',
  organization_id: 'organization_id',
  investment_model_id: 'investment_model_id',
  account_representative_id: 'account_representative_id',
  fee_schedule_id: 'fee_schedule_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on',
  archived_on: 'archived_on'
};

exports.Prisma.FeeScheduleScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  minimum: 'minimum',
  minimum_threshold: 'minimum_threshold',
  basis: 'basis',
  bill_entity: 'bill_entity',
  calculation: 'calculation',
  type: 'type',
  orion_id: 'orion_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on',
  archived_on: 'archived_on'
};

exports.Prisma.AccountRepresentativeScalarFieldEnum = {
  id: 'id',
  code: 'code',
  name: 'name',
  orion_id: 'orion_id',
  is_primary_override: 'is_primary_override',
  organization_id: 'organization_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.RepresentativePayableScalarFieldEnum = {
  id: 'id',
  period_start: 'period_start',
  period_end: 'period_end',
  billed_value: 'billed_value',
  client_fees: 'client_fees',
  client_adjustments: 'client_adjustments',
  rep_payout: 'rep_payout',
  url: 'url',
  orion_billing_id: 'orion_billing_id',
  orion_payable_id: 'orion_payable_id',
  account_representative_id: 'account_representative_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.PayableClientScalarFieldEnum = {
  id: 'id',
  name: 'name',
  representative_payable_id: 'representative_payable_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.PayableAccountScalarFieldEnum = {
  id: 'id',
  number: 'number',
  name: 'name',
  days_in_cycle: 'days_in_cycle',
  days_in_year: 'days_in_year',
  fee_schedule: 'fee_schedule',
  pay_schedule: 'pay_schedule',
  billed_value: 'billed_value',
  client_fees: 'client_fees',
  client_adjustments: 'client_adjustments',
  rep_payout: 'rep_payout',
  payable_client_id: 'payable_client_id',
  account_id: 'account_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AccountAddressScalarFieldEnum = {
  id: 'id',
  name: 'name',
  line_1: 'line_1',
  line_2: 'line_2',
  line_3: 'line_3',
  city: 'city',
  state: 'state',
  country: 'country',
  postal_code: 'postal_code',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SecurityAccountControlScalarFieldEnum = {
  id: 'id',
  control_type: 'control_type',
  security_id: 'security_id',
  account_id: 'account_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.DistributionScheduleScalarFieldEnum = {
  id: 'id',
  type: 'type',
  record_type: 'record_type',
  amount: 'amount',
  start_date: 'start_date',
  deleted_on: 'deleted_on',
  period_type: 'period_type',
  distribution_date: 'distribution_date',
  notes: 'notes',
  period_multiplier: 'period_multiplier',
  recurring_days: 'recurring_days',
  end_date: 'end_date',
  end_count: 'end_count',
  eclipse_id: 'eclipse_id',
  last_eclipse_export: 'last_eclipse_export',
  from_account_id: 'from_account_id',
  to_account_id: 'to_account_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.RoleScalarFieldEnum = {
  id: 'id',
  description: 'description',
  name: 'name',
  permissions: 'permissions',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  auth0_id: 'auth0_id',
  email: 'email',
  profile_picture: 'profile_picture',
  last_login: 'last_login',
  orion_email: 'orion_email',
  deleted_on: 'deleted_on',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AccountGroupScalarFieldEnum = {
  id: 'id',
  contact_status: 'contact_status',
  compound_user_id: 'compound_user_id',
  name: 'name',
  orion_household_id: 'orion_household_id',
  primary_client_id: 'primary_client_id',
  salesforce_household_id: 'salesforce_household_id',
  archived_on: 'archived_on',
  organization_id: 'organization_id',
  employee_id: 'employee_id',
  team_id: 'team_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AccountGroupMetaDataScalarFieldEnum = {
  id: 'id',
  overall_net_worth: 'overall_net_worth',
  liquid_net_worth: 'liquid_net_worth',
  last_login: 'last_login',
  ytd_meetings: 'ytd_meetings',
  investment_objective: 'investment_objective',
  investment_time_horizon: 'investment_time_horizon',
  additional_time_horizon_and_liquidity_provisions: 'additional_time_horizon_and_liquidity_provisions',
  past_employer: 'past_employer',
  current_employer: 'current_employer',
  primary_source_of_funds: 'primary_source_of_funds',
  other_sources_of_funds: 'other_sources_of_funds',
  investment_restrictions: 'investment_restrictions',
  investor_status: 'investor_status',
  employed_by_industry: 'employed_by_industry',
  investment_industry_entities: 'investment_industry_entities',
  is_broker_dealer: 'is_broker_dealer',
  is_investment_advisor: 'is_investment_advisor',
  is_finra: 'is_finra',
  is_regulator: 'is_regulator',
  is_corporate_officer: 'is_corporate_officer',
  companies_of_officership: 'companies_of_officership',
  is_internal_employee: 'is_internal_employee',
  is_political_official: 'is_political_official',
  countries_of_political_appointments: 'countries_of_political_appointments',
  account_group_id: 'account_group_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.NoteScalarFieldEnum = {
  id: 'id',
  text: 'text',
  last_edit: 'last_edit',
  first_edit: 'first_edit',
  salesforce_id: 'salesforce_id',
  note_type: 'note_type',
  account_group_id: 'account_group_id',
  employee_id: 'employee_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.MeetingLogScalarFieldEnum = {
  id: 'id',
  transcript_id: 'transcript_id',
  transcript_url: 'transcript_url',
  scheduled_date: 'scheduled_date',
  duration_in_seconds: 'duration_in_seconds',
  title: 'title',
  summary: 'summary',
  transcript: 'transcript',
  email_response: 'email_response',
  account_group_id: 'account_group_id',
  manual_note_id: 'manual_note_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.MeetingSummaryItemScalarFieldEnum = {
  id: 'id',
  type: 'type',
  header: 'header',
  items: 'items',
  meeting_log_id: 'meeting_log_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.ClientScalarFieldEnum = {
  id: 'id',
  salesforce_contact_id: 'salesforce_contact_id',
  salesforce_person_id: 'salesforce_person_id',
  name: 'name',
  nickname: 'nickname',
  name_prefix: 'name_prefix',
  first_name: 'first_name',
  middle_name: 'middle_name',
  last_name: 'last_name',
  name_suffix: 'name_suffix',
  gender: 'gender',
  birth_date: 'birth_date',
  anniversary_date: 'anniversary_date',
  client_since: 'client_since',
  marital_status: 'marital_status',
  risk_score: 'risk_score',
  location: 'location',
  phone: 'phone',
  phone_work: 'phone_work',
  phone_home: 'phone_home',
  citizenship_usa: 'citizenship_usa',
  citizenship_other: 'citizenship_other',
  residence: 'residence',
  accredited_investor_status: 'accredited_investor_status',
  entity_type: 'entity_type',
  authorized_representative: 'authorized_representative',
  compound_register_link: 'compound_register_link',
  register_link_last_sent: 'register_link_last_sent',
  has_signed_up: 'has_signed_up',
  type: 'type',
  employer: 'employer',
  employment_status: 'employment_status',
  account_group_id: 'account_group_id',
  user_id: 'user_id',
  organization_id: 'organization_id',
  risk_tolerance_submission_id: 'risk_tolerance_submission_id',
  home_address_id: 'home_address_id',
  mailing_address_id: 'mailing_address_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.ContactEmailScalarFieldEnum = {
  id: 'id',
  email_address: 'email_address',
  email_tags: 'email_tags',
  name: 'name',
  client_id: 'client_id',
  lead_id: 'lead_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.EmployeeScalarFieldEnum = {
  id: 'id',
  payment_user_id: 'payment_user_id',
  name: 'name',
  orion_id: 'orion_id',
  crd_number: 'crd_number',
  phone_number: 'phone_number',
  g_number: 'g_number',
  sl_master_number: 'sl_master_number',
  fa_master_override_number: 'fa_master_override_number',
  salesforce_contact_id: 'salesforce_contact_id',
  prior_firm_name: 'prior_firm_name',
  compound_user_id: 'compound_user_id',
  compound_register_link: 'compound_register_link',
  nylas_grant_id: 'nylas_grant_id',
  user_id: 'user_id',
  organization_id: 'organization_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.EmployeePreferencesScalarFieldEnum = {
  id: 'id',
  client_support_email: 'client_support_email',
  side_bar_expanded: 'side_bar_expanded',
  view_all_firm_data: 'view_all_firm_data',
  employee_id: 'employee_id',
  team_id: 'team_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.QuestionnaireScalarFieldEnum = {
  id: 'id',
  name: 'name',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.QuestionGroupScalarFieldEnum = {
  id: 'id',
  active: 'active',
  default_score: 'default_score',
  description: 'description',
  menu_label: 'menu_label',
  priority: 'priority',
  title: 'title',
  weight: 'weight',
  parent_group_id: 'parent_group_id',
  questionnaire_id: 'questionnaire_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.QuestionOptionScalarFieldEnum = {
  id: 'id',
  active: 'active',
  description: 'description',
  priority: 'priority',
  score: 'score',
  title: 'title',
  group_id: 'group_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.QuestionAnswerScalarFieldEnum = {
  id: 'id',
  option_id: 'option_id',
  submission_id: 'submission_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.QuestionnaireSubmissionScalarFieldEnum = {
  id: 'id',
  questionnaire_id: 'questionnaire_id',
  client_id: 'client_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.Proposal2ScalarFieldEnum = {
  id: 'id',
  name: 'name',
  risk_tolerance: 'risk_tolerance',
  proposed_advisory_fee: 'proposed_advisory_fee',
  comparison_advisory_fee: 'comparison_advisory_fee',
  total_assets: 'total_assets',
  include_alternatives: 'include_alternatives',
  is_qualified_purchaser: 'is_qualified_purchaser',
  is_accredited_investor: 'is_accredited_investor',
  net_worth: 'net_worth',
  alternative_exposure_percentage: 'alternative_exposure_percentage',
  status: 'status',
  advisor_id: 'advisor_id',
  creator_id: 'creator_id',
  investment_model_1_id: 'investment_model_1_id',
  investment_model_2_id: 'investment_model_2_id',
  account_group_id: 'account_group_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on',
  employeeId: 'employeeId'
};

exports.Prisma.Proposal2AssetScalarFieldEnum = {
  id: 'id',
  percentage: 'percentage',
  proposal_as_asset_id: 'proposal_as_asset_id',
  proposal_as_interval_fund_id: 'proposal_as_interval_fund_id',
  default_proposal_interval_fund_id: 'default_proposal_interval_fund_id',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.ProposalProxySecurityScalarFieldEnum = {
  id: 'id',
  proposal_id: 'proposal_id',
  security_id: 'security_id',
  proxy_security_id: 'proxy_security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.ProposalAlternativeScalarFieldEnum = {
  id: 'id',
  percentage: 'percentage',
  proposal_id: 'proposal_id',
  opportunity_id: 'opportunity_id',
  default_proposal_alternative_id: 'default_proposal_alternative_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.DefaultProposalAlternativesScalarFieldEnum = {
  id: 'id',
  risk_tolerance: 'risk_tolerance'
};

exports.Prisma.Proposal2ContentScalarFieldEnum = {
  id: 'id',
  summary_points: 'summary_points',
  welcome_text_paragraphs: 'welcome_text_paragraphs',
  disclosures: 'disclosures',
  section_order: 'section_order',
  hidden_section_ids: 'hidden_section_ids',
  hide_proposal_1_annual_return_from_summary: 'hide_proposal_1_annual_return_from_summary',
  hide_proposal_1_annual_volatility_from_summary: 'hide_proposal_1_annual_volatility_from_summary',
  hide_proposal_2_annual_return_from_summary: 'hide_proposal_2_annual_return_from_summary',
  hide_proposal_2_annual_volatility_from_summary: 'hide_proposal_2_annual_volatility_from_summary',
  proposal_id: 'proposal_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AnvilEnvelopeLinkCopyScalarFieldEnum = {
  id: 'id',
  copied_on: 'copied_on',
  anvil_envelope_signer_id: 'anvil_envelope_signer_id',
  employee_id: 'employee_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.ClientLinkScalarFieldEnum = {
  id: 'id',
  feature_type: 'feature_type',
  last_sent: 'last_sent',
  last_used: 'last_used',
  expiration: 'expiration',
  client_id: 'client_id',
  feature_id: 'feature_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.InvestmentModelWhitelistScalarFieldEnum = {
  id: 'id',
  name: 'name',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.InvestmentModelScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  disclosures: 'disclosures',
  proposal_summary_points: 'proposal_summary_points',
  proposal_welcome_paragraphs: 'proposal_welcome_paragraphs',
  cash_percentage: 'cash_percentage',
  risk_score: 'risk_score',
  orion_id: 'orion_id',
  last_orion_sync: 'last_orion_sync',
  shortname: 'shortname',
  is_separately_managed: 'is_separately_managed',
  organization_id: 'organization_id',
  factsheet_document_id: 'factsheet_document_id',
  team_id: 'team_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.InvestmentModelStrategyScalarFieldEnum = {
  id: 'id',
  percentage: 'percentage',
  strategy_id: 'strategy_id',
  model_id: 'model_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.InvestmentStrategyScalarFieldEnum = {
  id: 'id',
  logo: 'logo',
  minimum_investment: 'minimum_investment',
  name: 'name',
  disclosures: 'disclosures',
  sponsor: 'sponsor',
  description: 'description',
  strategy_type: 'strategy_type',
  risk_category: 'risk_category',
  tax_managed: 'tax_managed',
  esg: 'esg',
  asset_type: 'asset_type',
  fee: 'fee',
  highlights: 'highlights',
  factsheet_url: 'factsheet_url',
  eclipse_id: 'eclipse_id',
  eclipse_model_id: 'eclipse_model_id',
  last_eclipse_sync: 'last_eclipse_sync',
  shortname: 'shortname',
  performance: 'performance',
  organization_id: 'organization_id',
  team_id: 'team_id',
  provider_id: 'provider_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.ProviderScalarFieldEnum = {
  id: 'id',
  name: 'name',
  logo_url: 'logo_url',
  deleted_on: 'deleted_on',
  aum: 'aum',
  inception: 'inception',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.PortfolioManagerScalarFieldEnum = {
  id: 'id',
  name: 'name',
  disclosures: 'disclosures',
  qualifications: 'qualifications',
  provider_id: 'provider_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.InvestmentStrategySecurityScalarFieldEnum = {
  id: 'id',
  percentage: 'percentage',
  strategy_id: 'strategy_id',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SectorWeightScalarFieldEnum = {
  id: 'id',
  sector: 'sector',
  weight: 'weight',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SecurityScalarFieldEnum = {
  id: 'id',
  composite_figi: 'composite_figi',
  cusip: 'cusip',
  custodial_cash: 'custodial_cash',
  eclipse_id: 'eclipse_id',
  expense_ratio: 'expense_ratio',
  figi: 'figi',
  isin: 'isin',
  name: 'name',
  orion_asset_category: 'orion_asset_category',
  orion_asset_class: 'orion_asset_class',
  orion_id: 'orion_id',
  reference_id: 'reference_id',
  sector: 'sector',
  security_type: 'security_type',
  sources: 'sources',
  status: 'status',
  ticker: 'ticker',
  earliest_performance_date: 'earliest_performance_date',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SecurityDailyPriceScalarFieldEnum = {
  id: 'id',
  date: 'date',
  open: 'open',
  high: 'high',
  low: 'low',
  close: 'close',
  adjusted_close: 'adjusted_close',
  volume: 'volume',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AssetClassificationScalarFieldEnum = {
  id: 'id',
  identifier: 'identifier',
  identified_by: 'identified_by',
  provider: 'provider',
  asset_class: 'asset_class',
  metadata: 'metadata',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SubAssetClassificationScalarFieldEnum = {
  id: 'id',
  sub_asset_class: 'sub_asset_class',
  percentage: 'percentage',
  asset_classification_id: 'asset_classification_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SubAssetClassificationOverrideScalarFieldEnum = {
  id: 'id',
  sub_asset_class: 'sub_asset_class',
  pattern: 'pattern',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.PlanScalarFieldEnum = {
  id: 'id',
  default: 'default',
  entitlements: 'entitlements',
  name: 'name',
  status: 'status',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SubscriptionScalarFieldEnum = {
  id: 'id',
  canceled: 'canceled',
  initiated: 'initiated',
  seats: 'seats',
  status: 'status',
  organization_id: 'organization_id',
  plan_id: 'plan_id',
  paying_employee_id: 'paying_employee_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.JobScalarFieldEnum = {
  id: 'id',
  name: 'name',
  lock_id: 'lock_id',
  last_run: 'last_run',
  next_run: 'next_run',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.TradingReportRunScalarFieldEnum = {
  id: 'id',
  report_filter_from: 'report_filter_from',
  name: 'name',
  url: 'url',
  status: 'status',
  revision: 'revision',
  employee_id: 'employee_id',
  deleted_on: 'deleted_on',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.TradableEventScalarFieldEnum = {
  id: 'id',
  event_type: 'event_type',
  account_id: 'account_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SavedListScalarFieldEnum = {
  id: 'id',
  name: 'name',
  items: 'items',
  item_type: 'item_type',
  employee_id: 'employee_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.FundHolderScalarFieldEnum = {
  id: 'id',
  name: 'name',
  holder: 'holder',
  address: 'address',
  docusign_auth_type_required: 'docusign_auth_type_required',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.FundClosingSubscriptionDocumentScalarFieldEnum = {
  id: 'id',
  subscription_document_closing_id: 'subscription_document_closing_id',
  opportunity_id: 'opportunity_id',
  fund_holder_id: 'fund_holder_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AnvilEnvelopeSignerScalarFieldEnum = {
  id: 'id',
  order: 'order',
  status: 'status',
  sent_times: 'sent_times',
  user_id: 'user_id',
  anvil_envelope_id: 'anvil_envelope_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AnvilEnvelopeScalarFieldEnum = {
  id: 'id',
  weld_data_eid: 'weld_data_eid',
  webhook_eid: 'webhook_eid',
  next_signer_email: 'next_signer_email',
  custom_name: 'custom_name',
  next_destination: 'next_destination',
  envelope_type: 'envelope_type',
  status: 'status',
  continue_url: 'continue_url',
  client_onboarding_id: 'client_onboarding_id',
  envelope_type_id: 'envelope_type_id',
  ticket_id: 'ticket_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AnvilEnvelopeTypeDataScalarFieldEnum = {
  id: 'id',
  envelope_type: 'envelope_type',
  action: 'action',
  advisor_forge_eid: 'advisor_forge_eid',
  client_forge_eid: 'client_forge_eid',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.OnboardingAccountScalarFieldEnum = {
  id: 'id',
  account_number: 'account_number',
  archived_on: 'archived_on',
  custodian: 'custodian',
  status: 'status',
  salesforce_account_id: 'salesforce_account_id',
  account_id: 'account_id',
  account_group_id: 'account_group_id',
  onboarding_id: 'onboarding_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.ClientOnboardingScalarFieldEnum = {
  id: 'id',
  account_group_id: 'account_group_id',
  client_id: 'client_id',
  employee_id: 'employee_id',
  initiating_employee_id: 'initiating_employee_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SecuritySourceDataScalarFieldEnum = {
  id: 'id',
  date: 'date',
  raw: 'raw',
  source: 'source',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.SecurityDailyResultScalarFieldEnum = {
  id: 'id',
  conflicts: 'conflicts',
  data: 'data',
  date: 'date',
  security_id: 'security_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.TicketScalarFieldEnum = {
  id: 'id',
  description: 'description',
  source: 'source',
  source_id: 'source_id',
  source_created_on: 'source_created_on',
  source_updated_on: 'source_updated_on',
  status: 'status',
  subject: 'subject',
  salesforce_type: 'salesforce_type',
  type: 'type',
  account_group_id: 'account_group_id',
  requester_id: 'requester_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.TeamScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  template_team: 'template_team',
  applied_team_id: 'applied_team_id',
  plan_id: 'plan_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.TeamRoleScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  type: 'type',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.PermissionTemplateScalarFieldEnum = {
  id: 'id',
  name: 'name',
  scope: 'scope',
  entity: 'entity',
  action: 'action',
  team_role_id: 'team_role_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.TeamMemberScalarFieldEnum = {
  id: 'id',
  display_title: 'display_title',
  is_primary: 'is_primary',
  employee_id: 'employee_id',
  team_id: 'team_id',
  template_member_id: 'template_member_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.EmailMessageScalarFieldEnum = {
  id: 'id',
  body: 'body',
  subject: 'subject',
  message_preview: 'message_preview',
  external_id: 'external_id',
  date_received: 'date_received',
  email_message_thread_id: 'email_message_thread_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.MailboxScalarFieldEnum = {
  id: 'id',
  grant_id: 'grant_id',
  source: 'source',
  type: 'type',
  associated_employee_id: 'associated_employee_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.MailboxSyncScalarFieldEnum = {
  id: 'id',
  last_sync: 'last_sync',
  last_failure: 'last_failure',
  state: 'state',
  type: 'type',
  mailbox_id: 'mailbox_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.EmailMessageThreadScalarFieldEnum = {
  id: 'id',
  subject: 'subject',
  external_id: 'external_id',
  latest_message_date: 'latest_message_date',
  thread_preview: 'thread_preview',
  summary: 'summary',
  mailbox_id: 'mailbox_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.EmailAttachmentScalarFieldEnum = {
  id: 'id',
  external_id: 'external_id',
  name: 'name',
  size: 'size',
  email_message_id: 'email_message_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.LeadScalarFieldEnum = {
  id: 'id',
  name: 'name',
  contact_status: 'contact_status',
  source: 'source',
  source_category: 'source_category',
  first_name: 'first_name',
  last_name: 'last_name',
  source_id: 'source_id',
  associated_employee_id: 'associated_employee_id',
  client_id: 'client_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.LeadMetaDataScalarFieldEnum = {
  id: 'id',
  notes: 'notes',
  linkedin_url: 'linkedin_url',
  employer: 'employer',
  industry: 'industry',
  job_title: 'job_title',
  phone_number: 'phone_number',
  location: 'location',
  lead_id: 'lead_id',
  created_by: 'created_by',
  created_on: 'created_on',
  updated_by: 'updated_by',
  updated_on: 'updated_on'
};

exports.Prisma.AccountGroupBilledAUMScalarFieldEnum = {
  account_group__id: 'account_group__id',
  as_of: 'as_of',
  billed_aum: 'billed_aum'
};

exports.Prisma.AccountGroupBalanceScalarFieldEnum = {
  account_group__id: 'account_group__id',
  balance: 'balance'
};

exports.Prisma.ClientAccountAggregationScalarFieldEnum = {
  total_balance: 'total_balance',
  client__id: 'client__id'
};

exports.Prisma.PermissionScalarFieldEnum = {
  scope: 'scope',
  scope_id: 'scope_id',
  entity: 'entity',
  entity_id: 'entity_id',
  action: 'action',
  employee_id: 'employee_id',
  team_role_id: 'team_role_id',
  permission_template_id: 'permission_template_id'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.OpportunityOrderByRelevanceFieldEnum = {
  id: 'id',
  internal_id: 'internal_id',
  name: 'name',
  url: 'url',
  additional_offering_considerations: 'additional_offering_considerations',
  minimum_investment_considerations: 'minimum_investment_considerations',
  share_class: 'share_class',
  broker_dealer_name: 'broker_dealer_name',
  primary_asset_class: 'primary_asset_class',
  secondary_asset_class: 'secondary_asset_class',
  additional_characteristics: 'additional_characteristics',
  sector: 'sector',
  risk_notes: 'risk_notes',
  summary: 'summary',
  highlights: 'highlights',
  valuation_notes: 'valuation_notes',
  subscription_document_fund_id: 'subscription_document_fund_id',
  subdoc_file: 'subdoc_file',
  distribution_id: 'distribution_id',
  compliance_id: 'compliance_id',
  provider_id: 'provider_id',
  liquidity_id: 'liquidity_id',
  portfolio_manager_id: 'portfolio_manager_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.ValuationOrderByRelevanceFieldEnum = {
  id: 'id'
};

exports.Prisma.ComplianceOrderByRelevanceFieldEnum = {
  id: 'id',
  due_diligence_materials: 'due_diligence_materials',
  notes: 'notes',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.LiquidityOrderByRelevanceFieldEnum = {
  id: 'id',
  lockup_end: 'lockup_end',
  windows: 'windows',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.DistributionOrderByRelevanceFieldEnum = {
  id: 'id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.DocumentOrderByRelevanceFieldEnum = {
  id: 'id',
  external_id: 'external_id',
  name: 'name',
  url: 'url',
  opportunity_id: 'opportunity_id',
  employee_id: 'employee_id',
  investment_model_id: 'investment_model_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.OrganizationOrderByRelevanceFieldEnum = {
  id: 'id',
  logo: 'logo',
  name: 'name',
  website_url: 'website_url',
  orion_id: 'orion_id',
  paying_employee_id: 'paying_employee_id',
  parent_organization_id: 'parent_organization_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.DocumentLinkOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  url: 'url',
  organization_id: 'organization_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AssetOrderByRelevanceFieldEnum = {
  id: 'id',
  orion_id: 'orion_id',
  account_id: 'account_id',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.InvestmentTransactionOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  orion_id: 'orion_id',
  sub_code: 'sub_code',
  notes: 'notes',
  type: 'type',
  account_id: 'account_id',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.PerformanceBreakdownOrderByRelevanceFieldEnum = {
  id: 'id',
  created_by: 'created_by',
  updated_by: 'updated_by',
  account_id: 'account_id'
};

exports.Prisma.AssetSnapshotOrderByRelevanceFieldEnum = {
  id: 'id',
  orion_id: 'orion_id',
  account_id: 'account_id',
  asset_id: 'asset_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AlternativeAssetOrderByRelevanceFieldEnum = {
  id: 'id',
  orion_id: 'orion_id',
  fund_name: 'fund_name',
  class: 'class',
  account_id: 'account_id',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AccountOrderByRelevanceFieldEnum = {
  id: 'id',
  advisor_code: 'advisor_code',
  name: 'name',
  number: 'number',
  trade_notes: 'trade_notes',
  type: 'type',
  salesforce_account_id: 'salesforce_account_id',
  orion_id: 'orion_id',
  orion_registration_id: 'orion_registration_id',
  orion_status: 'orion_status',
  eclipse_id: 'eclipse_id',
  eclipse_portfolio_id: 'eclipse_portfolio_id',
  eclipse_cash_reserve_id: 'eclipse_cash_reserve_id',
  last_trade_generation_batch_id: 'last_trade_generation_batch_id',
  address_id: 'address_id',
  account_group_id: 'account_group_id',
  organization_id: 'organization_id',
  investment_model_id: 'investment_model_id',
  account_representative_id: 'account_representative_id',
  fee_schedule_id: 'fee_schedule_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.FeeScheduleOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  bill_entity: 'bill_entity',
  orion_id: 'orion_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AccountRepresentativeOrderByRelevanceFieldEnum = {
  id: 'id',
  code: 'code',
  name: 'name',
  orion_id: 'orion_id',
  organization_id: 'organization_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.RepresentativePayableOrderByRelevanceFieldEnum = {
  id: 'id',
  url: 'url',
  orion_billing_id: 'orion_billing_id',
  orion_payable_id: 'orion_payable_id',
  account_representative_id: 'account_representative_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.PayableClientOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  representative_payable_id: 'representative_payable_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.PayableAccountOrderByRelevanceFieldEnum = {
  id: 'id',
  number: 'number',
  name: 'name',
  fee_schedule: 'fee_schedule',
  pay_schedule: 'pay_schedule',
  payable_client_id: 'payable_client_id',
  account_id: 'account_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AccountAddressOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  line_1: 'line_1',
  line_2: 'line_2',
  line_3: 'line_3',
  city: 'city',
  state: 'state',
  country: 'country',
  postal_code: 'postal_code',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SecurityAccountControlOrderByRelevanceFieldEnum = {
  id: 'id',
  security_id: 'security_id',
  account_id: 'account_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.DistributionScheduleOrderByRelevanceFieldEnum = {
  id: 'id',
  notes: 'notes',
  eclipse_id: 'eclipse_id',
  from_account_id: 'from_account_id',
  to_account_id: 'to_account_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.RoleOrderByRelevanceFieldEnum = {
  id: 'id',
  description: 'description',
  name: 'name',
  permissions: 'permissions',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.UserOrderByRelevanceFieldEnum = {
  id: 'id',
  auth0_id: 'auth0_id',
  email: 'email',
  profile_picture: 'profile_picture',
  orion_email: 'orion_email',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AccountGroupOrderByRelevanceFieldEnum = {
  id: 'id',
  compound_user_id: 'compound_user_id',
  name: 'name',
  orion_household_id: 'orion_household_id',
  primary_client_id: 'primary_client_id',
  salesforce_household_id: 'salesforce_household_id',
  organization_id: 'organization_id',
  employee_id: 'employee_id',
  team_id: 'team_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AccountGroupMetaDataOrderByRelevanceFieldEnum = {
  id: 'id',
  investment_time_horizon: 'investment_time_horizon',
  additional_time_horizon_and_liquidity_provisions: 'additional_time_horizon_and_liquidity_provisions',
  past_employer: 'past_employer',
  current_employer: 'current_employer',
  primary_source_of_funds: 'primary_source_of_funds',
  other_sources_of_funds: 'other_sources_of_funds',
  investment_restrictions: 'investment_restrictions',
  investment_industry_entities: 'investment_industry_entities',
  companies_of_officership: 'companies_of_officership',
  countries_of_political_appointments: 'countries_of_political_appointments',
  account_group_id: 'account_group_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.NoteOrderByRelevanceFieldEnum = {
  id: 'id',
  text: 'text',
  salesforce_id: 'salesforce_id',
  account_group_id: 'account_group_id',
  employee_id: 'employee_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.MeetingLogOrderByRelevanceFieldEnum = {
  id: 'id',
  transcript_id: 'transcript_id',
  transcript_url: 'transcript_url',
  title: 'title',
  summary: 'summary',
  email_response: 'email_response',
  account_group_id: 'account_group_id',
  manual_note_id: 'manual_note_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.MeetingSummaryItemOrderByRelevanceFieldEnum = {
  id: 'id',
  header: 'header',
  items: 'items',
  meeting_log_id: 'meeting_log_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.ClientOrderByRelevanceFieldEnum = {
  id: 'id',
  salesforce_contact_id: 'salesforce_contact_id',
  salesforce_person_id: 'salesforce_person_id',
  name: 'name',
  nickname: 'nickname',
  name_prefix: 'name_prefix',
  first_name: 'first_name',
  middle_name: 'middle_name',
  last_name: 'last_name',
  name_suffix: 'name_suffix',
  location: 'location',
  phone: 'phone',
  phone_work: 'phone_work',
  phone_home: 'phone_home',
  citizenship_other: 'citizenship_other',
  residence: 'residence',
  authorized_representative: 'authorized_representative',
  compound_register_link: 'compound_register_link',
  employer: 'employer',
  account_group_id: 'account_group_id',
  user_id: 'user_id',
  organization_id: 'organization_id',
  risk_tolerance_submission_id: 'risk_tolerance_submission_id',
  home_address_id: 'home_address_id',
  mailing_address_id: 'mailing_address_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.ContactEmailOrderByRelevanceFieldEnum = {
  id: 'id',
  email_address: 'email_address',
  name: 'name',
  client_id: 'client_id',
  lead_id: 'lead_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.EmployeeOrderByRelevanceFieldEnum = {
  id: 'id',
  payment_user_id: 'payment_user_id',
  name: 'name',
  orion_id: 'orion_id',
  crd_number: 'crd_number',
  phone_number: 'phone_number',
  g_number: 'g_number',
  sl_master_number: 'sl_master_number',
  fa_master_override_number: 'fa_master_override_number',
  salesforce_contact_id: 'salesforce_contact_id',
  prior_firm_name: 'prior_firm_name',
  compound_user_id: 'compound_user_id',
  compound_register_link: 'compound_register_link',
  user_id: 'user_id',
  organization_id: 'organization_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.EmployeePreferencesOrderByRelevanceFieldEnum = {
  id: 'id',
  client_support_email: 'client_support_email',
  employee_id: 'employee_id',
  team_id: 'team_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.QuestionnaireOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.QuestionGroupOrderByRelevanceFieldEnum = {
  id: 'id',
  description: 'description',
  menu_label: 'menu_label',
  title: 'title',
  parent_group_id: 'parent_group_id',
  questionnaire_id: 'questionnaire_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.QuestionOptionOrderByRelevanceFieldEnum = {
  id: 'id',
  description: 'description',
  title: 'title',
  group_id: 'group_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.QuestionAnswerOrderByRelevanceFieldEnum = {
  id: 'id',
  option_id: 'option_id',
  submission_id: 'submission_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.QuestionnaireSubmissionOrderByRelevanceFieldEnum = {
  id: 'id',
  questionnaire_id: 'questionnaire_id',
  client_id: 'client_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.Proposal2OrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  advisor_id: 'advisor_id',
  creator_id: 'creator_id',
  investment_model_1_id: 'investment_model_1_id',
  investment_model_2_id: 'investment_model_2_id',
  account_group_id: 'account_group_id',
  created_by: 'created_by',
  updated_by: 'updated_by',
  employeeId: 'employeeId'
};

exports.Prisma.Proposal2AssetOrderByRelevanceFieldEnum = {
  id: 'id',
  proposal_as_asset_id: 'proposal_as_asset_id',
  proposal_as_interval_fund_id: 'proposal_as_interval_fund_id',
  default_proposal_interval_fund_id: 'default_proposal_interval_fund_id',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.ProposalProxySecurityOrderByRelevanceFieldEnum = {
  id: 'id',
  proposal_id: 'proposal_id',
  security_id: 'security_id',
  proxy_security_id: 'proxy_security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.ProposalAlternativeOrderByRelevanceFieldEnum = {
  id: 'id',
  proposal_id: 'proposal_id',
  opportunity_id: 'opportunity_id',
  default_proposal_alternative_id: 'default_proposal_alternative_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.DefaultProposalAlternativesOrderByRelevanceFieldEnum = {
  id: 'id'
};

exports.Prisma.Proposal2ContentOrderByRelevanceFieldEnum = {
  id: 'id',
  summary_points: 'summary_points',
  welcome_text_paragraphs: 'welcome_text_paragraphs',
  proposal_id: 'proposal_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AnvilEnvelopeLinkCopyOrderByRelevanceFieldEnum = {
  id: 'id',
  anvil_envelope_signer_id: 'anvil_envelope_signer_id',
  employee_id: 'employee_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.ClientLinkOrderByRelevanceFieldEnum = {
  id: 'id',
  client_id: 'client_id',
  feature_id: 'feature_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.InvestmentModelWhitelistOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.InvestmentModelOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  proposal_summary_points: 'proposal_summary_points',
  proposal_welcome_paragraphs: 'proposal_welcome_paragraphs',
  orion_id: 'orion_id',
  shortname: 'shortname',
  organization_id: 'organization_id',
  factsheet_document_id: 'factsheet_document_id',
  team_id: 'team_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.InvestmentModelStrategyOrderByRelevanceFieldEnum = {
  id: 'id',
  strategy_id: 'strategy_id',
  model_id: 'model_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.InvestmentStrategyOrderByRelevanceFieldEnum = {
  id: 'id',
  logo: 'logo',
  name: 'name',
  sponsor: 'sponsor',
  description: 'description',
  strategy_type: 'strategy_type',
  highlights: 'highlights',
  factsheet_url: 'factsheet_url',
  eclipse_id: 'eclipse_id',
  eclipse_model_id: 'eclipse_model_id',
  shortname: 'shortname',
  organization_id: 'organization_id',
  team_id: 'team_id',
  provider_id: 'provider_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.ProviderOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  logo_url: 'logo_url',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.PortfolioManagerOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  disclosures: 'disclosures',
  qualifications: 'qualifications',
  provider_id: 'provider_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.InvestmentStrategySecurityOrderByRelevanceFieldEnum = {
  id: 'id',
  strategy_id: 'strategy_id',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SectorWeightOrderByRelevanceFieldEnum = {
  id: 'id',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SecurityOrderByRelevanceFieldEnum = {
  id: 'id',
  composite_figi: 'composite_figi',
  cusip: 'cusip',
  eclipse_id: 'eclipse_id',
  figi: 'figi',
  isin: 'isin',
  name: 'name',
  orion_asset_class: 'orion_asset_class',
  orion_id: 'orion_id',
  reference_id: 'reference_id',
  ticker: 'ticker',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SecurityDailyPriceOrderByRelevanceFieldEnum = {
  id: 'id',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AssetClassificationOrderByRelevanceFieldEnum = {
  id: 'id',
  identifier: 'identifier',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SubAssetClassificationOrderByRelevanceFieldEnum = {
  id: 'id',
  asset_classification_id: 'asset_classification_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SubAssetClassificationOverrideOrderByRelevanceFieldEnum = {
  id: 'id',
  pattern: 'pattern',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.PlanOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SubscriptionOrderByRelevanceFieldEnum = {
  id: 'id',
  organization_id: 'organization_id',
  plan_id: 'plan_id',
  paying_employee_id: 'paying_employee_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.JobOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.TradingReportRunOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  url: 'url',
  employee_id: 'employee_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.TradableEventOrderByRelevanceFieldEnum = {
  id: 'id',
  account_id: 'account_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SavedListOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  items: 'items',
  employee_id: 'employee_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.FundHolderOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  address: 'address',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.FundClosingSubscriptionDocumentOrderByRelevanceFieldEnum = {
  id: 'id',
  subscription_document_closing_id: 'subscription_document_closing_id',
  opportunity_id: 'opportunity_id',
  fund_holder_id: 'fund_holder_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AnvilEnvelopeSignerOrderByRelevanceFieldEnum = {
  id: 'id',
  user_id: 'user_id',
  anvil_envelope_id: 'anvil_envelope_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AnvilEnvelopeOrderByRelevanceFieldEnum = {
  id: 'id',
  weld_data_eid: 'weld_data_eid',
  webhook_eid: 'webhook_eid',
  next_signer_email: 'next_signer_email',
  custom_name: 'custom_name',
  continue_url: 'continue_url',
  client_onboarding_id: 'client_onboarding_id',
  envelope_type_id: 'envelope_type_id',
  ticket_id: 'ticket_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AnvilEnvelopeTypeDataOrderByRelevanceFieldEnum = {
  id: 'id',
  advisor_forge_eid: 'advisor_forge_eid',
  client_forge_eid: 'client_forge_eid',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.OnboardingAccountOrderByRelevanceFieldEnum = {
  id: 'id',
  account_number: 'account_number',
  salesforce_account_id: 'salesforce_account_id',
  account_id: 'account_id',
  account_group_id: 'account_group_id',
  onboarding_id: 'onboarding_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.ClientOnboardingOrderByRelevanceFieldEnum = {
  id: 'id',
  account_group_id: 'account_group_id',
  client_id: 'client_id',
  employee_id: 'employee_id',
  initiating_employee_id: 'initiating_employee_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SecuritySourceDataOrderByRelevanceFieldEnum = {
  id: 'id',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.SecurityDailyResultOrderByRelevanceFieldEnum = {
  id: 'id',
  security_id: 'security_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.TicketOrderByRelevanceFieldEnum = {
  id: 'id',
  description: 'description',
  source_id: 'source_id',
  subject: 'subject',
  account_group_id: 'account_group_id',
  requester_id: 'requester_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.TeamOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  applied_team_id: 'applied_team_id',
  plan_id: 'plan_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.TeamRoleOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.PermissionTemplateOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  team_role_id: 'team_role_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.TeamMemberOrderByRelevanceFieldEnum = {
  id: 'id',
  display_title: 'display_title',
  employee_id: 'employee_id',
  team_id: 'team_id',
  template_member_id: 'template_member_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.EmailMessageOrderByRelevanceFieldEnum = {
  id: 'id',
  body: 'body',
  subject: 'subject',
  message_preview: 'message_preview',
  external_id: 'external_id',
  email_message_thread_id: 'email_message_thread_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.MailboxOrderByRelevanceFieldEnum = {
  id: 'id',
  grant_id: 'grant_id',
  associated_employee_id: 'associated_employee_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.MailboxSyncOrderByRelevanceFieldEnum = {
  id: 'id',
  mailbox_id: 'mailbox_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.EmailMessageThreadOrderByRelevanceFieldEnum = {
  id: 'id',
  subject: 'subject',
  external_id: 'external_id',
  thread_preview: 'thread_preview',
  summary: 'summary',
  mailbox_id: 'mailbox_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.EmailAttachmentOrderByRelevanceFieldEnum = {
  id: 'id',
  external_id: 'external_id',
  name: 'name',
  email_message_id: 'email_message_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.LeadOrderByRelevanceFieldEnum = {
  id: 'id',
  name: 'name',
  first_name: 'first_name',
  last_name: 'last_name',
  source_id: 'source_id',
  associated_employee_id: 'associated_employee_id',
  client_id: 'client_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.LeadMetaDataOrderByRelevanceFieldEnum = {
  id: 'id',
  notes: 'notes',
  linkedin_url: 'linkedin_url',
  employer: 'employer',
  industry: 'industry',
  job_title: 'job_title',
  phone_number: 'phone_number',
  location: 'location',
  lead_id: 'lead_id',
  created_by: 'created_by',
  updated_by: 'updated_by'
};

exports.Prisma.AccountGroupBilledAUMOrderByRelevanceFieldEnum = {
  account_group__id: 'account_group__id'
};

exports.Prisma.AccountGroupBalanceOrderByRelevanceFieldEnum = {
  account_group__id: 'account_group__id'
};

exports.Prisma.ClientAccountAggregationOrderByRelevanceFieldEnum = {
  client__id: 'client__id'
};

exports.Prisma.PermissionOrderByRelevanceFieldEnum = {
  scope_id: 'scope_id',
  entity_id: 'entity_id',
  employee_id: 'employee_id',
  team_role_id: 'team_role_id',
  permission_template_id: 'permission_template_id'
};
exports.OpportunityStatus = exports.$Enums.OpportunityStatus = {
  Hold: 'Hold',
  Open: 'Open',
  PendingDiligence: 'PendingDiligence',
  InDiligence: 'InDiligence',
  Approved: 'Approved',
  Closed: 'Closed',
  Completed: 'Completed'
};

exports.HoldReason = exports.$Enums.HoldReason = {
  Internal: 'Internal',
  Regulatory: 'Regulatory'
};

exports.OpportunityClosing = exports.$Enums.OpportunityClosing = {
  OneClose: 'OneClose',
  MultipleCloses: 'MultipleCloses',
  PerpetualOffering: 'PerpetualOffering'
};

exports.InvestorEligibility = exports.$Enums.InvestorEligibility = {
  Unrestricted: 'Unrestricted',
  BlueSky: 'BlueSky',
  Accredited: 'Accredited',
  QualifiedClient: 'QualifiedClient',
  QualifiedPurchaser: 'QualifiedPurchaser'
};

exports.ClosingFramework = exports.$Enums.ClosingFramework = {
  None: 'None',
  Greenshoe: 'Greenshoe',
  Perpetual: 'Perpetual'
};

exports.InvestmentType = exports.$Enums.InvestmentType = {
  DirectOwnership: 'DirectOwnership',
  PassthroughOwnership: 'PassthroughOwnership',
  Fund: 'Fund',
  FundofFunds: 'FundofFunds',
  Other: 'Other',
  None: 'None'
};

exports.RegistrationType = exports.$Enums.RegistrationType = {
  ClosedEndedMutualFund: 'ClosedEndedMutualFund',
  ClosedEndedREIT: 'ClosedEndedREIT',
  IntervalMutualFund: 'IntervalMutualFund',
  IntrastateOffering: 'IntrastateOffering',
  NonTradedBDC: 'NonTradedBDC',
  NonTradedCovered: 'NonTradedCovered',
  NonTradedPreferredStock: 'NonTradedPreferredStock',
  RegA: 'RegA',
  RegD506B: 'RegD506B',
  RegD506C: 'RegD506C',
  ThreeC1: 'ThreeC1',
  ThreeC7: 'ThreeC7'
};

exports.RiskCategory = exports.$Enums.RiskCategory = {
  Aggressive: 'Aggressive',
  AggressiveGrowth: 'AggressiveGrowth',
  Growth: 'Growth',
  ModeratelyAggressive: 'ModeratelyAggressive',
  Moderate: 'Moderate',
  Balanced: 'Balanced',
  ModeratelyConservative: 'ModeratelyConservative',
  Conservative: 'Conservative',
  DividendGrowth: 'DividendGrowth',
  DiversifierRisk: 'DiversifierRisk',
  AggressiveRisk: 'AggressiveRisk',
  Preservation: 'Preservation',
  Speculative: 'Speculative',
  ModerateGrowth: 'ModerateGrowth',
  ConservativeBalanced: 'ConservativeBalanced',
  CapitalPreservation: 'CapitalPreservation'
};

exports.InvestmentObjective = exports.$Enums.InvestmentObjective = {
  CapitalPreservation: 'CapitalPreservation',
  Growth: 'Growth',
  Income: 'Income',
  TaxAlpha: 'TaxAlpha',
  Unknown: 'Unknown'
};

exports.Geography = exports.$Enums.Geography = {
  US: 'US',
  Developed: 'Developed',
  Emerging: 'Emerging',
  Mixed: 'Mixed',
  Unknown: 'Unknown'
};

exports.HoldingsComposition = exports.$Enums.HoldingsComposition = {
  SingleAsset: 'SingleAsset',
  ConcentratedPortfolio: 'ConcentratedPortfolio',
  BlindConcentratedPortfolio: 'BlindConcentratedPortfolio',
  DiversifiedPortfolio: 'DiversifiedPortfolio',
  BlindDiversifiedPortfolio: 'BlindDiversifiedPortfolio'
};

exports.GicsSector = exports.$Enums.GicsSector = {
  CommunicationServices: 'CommunicationServices',
  ConsumerDiscretionary: 'ConsumerDiscretionary',
  ConsumerServices: 'ConsumerServices',
  ConsumerStaples: 'ConsumerStaples',
  Energy: 'Energy',
  Financials: 'Financials',
  Healthcare: 'Healthcare',
  Industrials: 'Industrials',
  Materials: 'Materials',
  Miscellaneous: 'Miscellaneous',
  RealEstate: 'RealEstate',
  Technology: 'Technology',
  Utilities: 'Utilities'
};

exports.CommissionLocation = exports.$Enums.CommissionLocation = {
  External: 'External',
  Internal: 'Internal',
  NoFee: 'NoFee'
};

exports.UbtiQualified = exports.$Enums.UbtiQualified = {
  Yes: 'Yes',
  No: 'No',
  Unknown: 'Unknown'
};

exports.TaxReporting = exports.$Enums.TaxReporting = {
  TenNintyNine: 'TenNintyNine',
  K1MultipleState: 'K1MultipleState',
  K1SingleState: 'K1SingleState',
  MultiStateK1: 'MultiStateK1',
  Other: 'Other'
};

exports.ValuationFrequency = exports.$Enums.ValuationFrequency = {
  Daily: 'Daily',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  Annually: 'Annually',
  AdHoc: 'AdHoc',
  OnCompletion: 'OnCompletion',
  Other: 'Other'
};

exports.PerformanceExpectation = exports.$Enums.PerformanceExpectation = {
  ConsiderablyUneven: 'ConsiderablyUneven',
  Flat: 'Flat',
  Increasing: 'Increasing',
  ModeratelyUneven: 'ModeratelyUneven',
  Other: 'Other',
  Unknown: 'Unknown'
};

exports.HoldingsExitOption = exports.$Enums.HoldingsExitOption = {
  Refinancing: 'Refinancing',
  Listing: 'Listing',
  IndividualAssetSales: 'IndividualAssetSales',
  PortfolioSale: 'PortfolioSale'
};

exports.SellingAgreement = exports.$Enums.SellingAgreement = {
  NotRequired: 'NotRequired',
  PerOffering: 'PerOffering',
  Omnibus: 'Omnibus'
};

exports.LiquidityType = exports.$Enums.LiquidityType = {
  FullyLiquid: 'FullyLiquid',
  TimeBasedLiquidityWindows: 'TimeBasedLiquidityWindows',
  IlliquidTillEvent: 'IlliquidTillEvent'
};

exports.DistributionQuality = exports.$Enums.DistributionQuality = {
  Correlated: 'Correlated',
  InverselyCorrelated: 'InverselyCorrelated',
  Uncorrelated: 'Uncorrelated',
  NA: 'NA'
};

exports.DistributionTiming = exports.$Enums.DistributionTiming = {
  AdHoc: 'AdHoc',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  Annually: 'Annually',
  Other: 'Other'
};

exports.DistributionCurve = exports.$Enums.DistributionCurve = {
  Flat: 'Flat',
  Declining: 'Declining',
  Increasing: 'Increasing',
  Irregular: 'Irregular',
  Unknown: 'Unknown',
  NA: 'NA'
};

exports.DistributionComposition = exports.$Enums.DistributionComposition = {
  Operations: 'Operations',
  ValuationGains: 'ValuationGains',
  ReturnOfCapital: 'ReturnOfCapital',
  NA: 'NA'
};

exports.DocumentType = exports.$Enums.DocumentType = {
  Factsheet: 'Factsheet',
  PPM: 'PPM',
  Diligence: 'Diligence',
  SubscriptionDocument: 'SubscriptionDocument',
  ADV2B: 'ADV2B',
  ClientRiskDocumentation: 'ClientRiskDocumentation'
};

exports.DocumentSource = exports.$Enums.DocumentSource = {
  Manual: 'Manual',
  Passthrough: 'Passthrough'
};

exports.AccountSource = exports.$Enums.AccountSource = {
  Manual: 'Manual',
  Bridge: 'Bridge',
  Orion: 'Orion'
};

exports.Custodian = exports.$Enums.Custodian = {
  NFS: 'NFS',
  PER: 'PER',
  SWB: 'SWB',
  Folio: 'Folio',
  Kingdom: 'Kingdom',
  ETC: 'ETC',
  FundDirect: 'FundDirect',
  Axos: 'Axos',
  Nationwide: 'Nationwide',
  ByAllAccounts: 'ByAllAccounts',
  Nuview: 'Nuview',
  NuViewTrust: 'NuViewTrust',
  Ascensus: 'Ascensus',
  GoldmanSachs: 'GoldmanSachs',
  Unknown: 'Unknown'
};

exports.AccountStatus = exports.$Enums.AccountStatus = {
  Proposal: 'Proposal',
  Open: 'Open',
  Closed: 'Closed',
  Missing: 'Missing'
};

exports.TradingBlockedReason = exports.$Enums.TradingBlockedReason = {
  AdvisorRequest: 'AdvisorRequest',
  Closed: 'Closed',
  Restricted: 'Restricted',
  ManagedBySMA: 'ManagedBySMA'
};

exports.CapitalGainsAllowance = exports.$Enums.CapitalGainsAllowance = {
  Allow: 'Allow',
  FullPositionDisallow: 'FullPositionDisallow',
  TaxLotDisallow: 'TaxLotDisallow'
};

exports.AccountImplementation = exports.$Enums.AccountImplementation = {
  TBills: 'TBills',
  Options: 'Options',
  Model: 'Model',
  Alternatives: 'Alternatives',
  SMA: 'SMA',
  Cash: 'Cash'
};

exports.FeeScheduleBasis = exports.$Enums.FeeScheduleBasis = {
  Account: 'Account',
  Household: 'Household',
  Registration: 'Registration',
  SubAdvisor: 'SubAdvisor'
};

exports.FeeScheduleCalculation = exports.$Enums.FeeScheduleCalculation = {
  Dollar: 'Dollar',
  Percentage: 'Percentage'
};

exports.FeeScheduleType = exports.$Enums.FeeScheduleType = {
  Tiered: 'Tiered',
  Flat: 'Flat',
  Linear: 'Linear'
};

exports.SecurityControlType = exports.$Enums.SecurityControlType = {
  Unsupervised: 'Unsupervised',
  Restricted: 'Restricted'
};

exports.DistributionType = exports.$Enums.DistributionType = {
  UserDefined: 'UserDefined',
  RequiredMinimum: 'RequiredMinimum'
};

exports.DistributionRecordType = exports.$Enums.DistributionRecordType = {
  CashRaise: 'CashRaise',
  MoneyMovement: 'MoneyMovement'
};

exports.PeriodType = exports.$Enums.PeriodType = {
  ONCE: 'ONCE',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY'
};

exports.ContactStatus = exports.$Enums.ContactStatus = {
  Cold: 'Cold',
  ContactedNotConnected: 'ContactedNotConnected',
  NeedABetterNumber: 'NeedABetterNumber',
  PhoneNumberUpdated: 'PhoneNumberUpdated',
  ConnectedFrozen: 'ConnectedFrozen',
  ConnectedFollowUpRequested: 'ConnectedFollowUpRequested',
  Opportunity: 'Opportunity',
  PaperworkSent: 'PaperworkSent',
  Client: 'Client',
  Terminated: 'Terminated'
};

exports.AccreditedInvestorStatus = exports.$Enums.AccreditedInvestorStatus = {
  Retail: 'Retail',
  Accredited: 'Accredited',
  QualifiedPurchaser: 'QualifiedPurchaser'
};

exports.NoteType = exports.$Enums.NoteType = {
  Call: 'Call',
  General: 'General'
};

exports.AutomatedItemType = exports.$Enums.AutomatedItemType = {
  Note: 'Note',
  ActionItem: 'ActionItem'
};

exports.Gender = exports.$Enums.Gender = {
  Male: 'Male',
  Female: 'Female',
  NonBinary: 'NonBinary',
  Unknown: 'Unknown'
};

exports.MaritalStatus = exports.$Enums.MaritalStatus = {
  Married: 'Married',
  Single: 'Single',
  Divorced: 'Divorced',
  Widowed: 'Widowed',
  LifePartner: 'LifePartner',
  Separated: 'Separated',
  Unknown: 'Unknown'
};

exports.ClientEntityType = exports.$Enums.ClientEntityType = {
  Individual: 'Individual',
  LegalEntity: 'LegalEntity'
};

exports.CompoundClientType = exports.$Enums.CompoundClientType = {
  Prospect: 'Prospect',
  Client: 'Client'
};

exports.EmploymentStatus = exports.$Enums.EmploymentStatus = {
  Employed: 'Employed',
  SelfEmployed: 'SelfEmployed',
  Retired: 'Retired',
  Homemaker: 'Homemaker',
  Student: 'Student',
  NotEmployed: 'NotEmployed'
};

exports.ContactEmailTag = exports.$Enums.ContactEmailTag = {
  Dashboard: 'Dashboard'
};

exports.RiskTolerance = exports.$Enums.RiskTolerance = {
  Conservative: 'Conservative',
  ModeratelyConservative: 'ModeratelyConservative',
  Moderate: 'Moderate',
  ModeratelyAggressive: 'ModeratelyAggressive',
  Growth: 'Growth',
  Speculative: 'Speculative'
};

exports.Proposal2Status = exports.$Enums.Proposal2Status = {
  Draft: 'Draft',
  Sent: 'Sent',
  Opened: 'Opened',
  Accepted: 'Accepted'
};

exports.Proposal2SectionId = exports.$Enums.Proposal2SectionId = {
  Introduction: 'Introduction',
  Summary: 'Summary',
  PortfolioComparison: 'PortfolioComparison',
  Performance: 'Performance',
  Fees: 'Fees',
  MonteCarlo: 'MonteCarlo',
  Risk: 'Risk',
  HighLevelAssetClassDiversification: 'HighLevelAssetClassDiversification',
  SectorDiversification: 'SectorDiversification',
  ProposedPrivateInvestments: 'ProposedPrivateInvestments',
  Disclosures: 'Disclosures'
};

exports.LinkFeatureType = exports.$Enums.LinkFeatureType = {
  ClientOnboarding: 'ClientOnboarding',
  InvestmentOpportunity: 'InvestmentOpportunity',
  Proposals: 'Proposals'
};

exports.AssetType = exports.$Enums.AssetType = {
  ETF: 'ETF',
  Mixed: 'Mixed',
  Stock: 'Stock',
  Equity: 'Equity',
  MutualFund: 'MutualFund'
};

exports.OrionAssetCategory = exports.$Enums.OrionAssetCategory = {
  Equity: 'Equity',
  Bond: 'Bond',
  MoneyMarket: 'MoneyMarket',
  CashAndMoneyMarket: 'CashAndMoneyMarket'
};

exports.SecurityType = exports.$Enums.SecurityType = {
  Alternative: 'Alternative',
  Bond: 'Bond',
  Cash: 'Cash',
  CashEquivalent: 'CashEquivalent',
  ETF: 'ETF',
  MutualFund: 'MutualFund',
  Option: 'Option',
  Stock: 'Stock',
  CD: 'CD',
  Unknown: 'Unknown'
};

exports.SecurityStatus = exports.$Enums.SecurityStatus = {
  Partial: 'Partial',
  Missing: 'Missing',
  Complete: 'Complete'
};

exports.SecuritySource = exports.$Enums.SecuritySource = {
  EOD: 'EOD',
  Orion: 'Orion',
  Eclipse: 'Eclipse',
  Xignite: 'Xignite',
  OpenFIGI: 'OpenFIGI',
  Private: 'Private'
};

exports.AssetClassificationIdentifierType = exports.$Enums.AssetClassificationIdentifierType = {
  Ticker: 'Ticker',
  Cusip: 'Cusip',
  Isin: 'Isin',
  Figi: 'Figi',
  AdvisorHQ: 'AdvisorHQ'
};

exports.AssetClassificationProvider = exports.$Enums.AssetClassificationProvider = {
  Factset: 'Factset',
  Orion: 'Orion',
  Manual: 'Manual'
};

exports.AssetClass = exports.$Enums.AssetClass = {
  Equity: 'Equity',
  FixedIncome: 'FixedIncome',
  Alternative: 'Alternative',
  CashAndEquivalents: 'CashAndEquivalents',
  Other: 'Other',
  Unknown: 'Unknown'
};

exports.SubAssetClass = exports.$Enums.SubAssetClass = {
  USEquitiesLargeCap: 'USEquitiesLargeCap',
  USEquitiesSmallMidCap: 'USEquitiesSmallMidCap',
  USEquitiesOther: 'USEquitiesOther',
  InternationalEquitiesDevelopedExUS: 'InternationalEquitiesDevelopedExUS',
  InternationalEquitiesEmergingMarkets: 'InternationalEquitiesEmergingMarkets',
  InternationalEquitiesOther: 'InternationalEquitiesOther',
  PublicRealEstateFunds: 'PublicRealEstateFunds',
  Commodities: 'Commodities',
  USFixedIncome: 'USFixedIncome',
  InternationalFixedIncome: 'InternationalFixedIncome',
  TIPS: 'TIPS',
  OtherFixedIncome: 'OtherFixedIncome',
  HedgeFunds: 'HedgeFunds',
  PrivateCreditFunds: 'PrivateCreditFunds',
  PrivateEquityFunds: 'PrivateEquityFunds',
  VentureCapitalFunds: 'VentureCapitalFunds',
  PrivateRealEstateFunds: 'PrivateRealEstateFunds',
  OtherAlternatives: 'OtherAlternatives',
  Cash: 'Cash',
  CashEquivalents: 'CashEquivalents',
  Options: 'Options',
  OtherPublicAssets: 'OtherPublicAssets',
  Unknown: 'Unknown'
};

exports.PlanStatus = exports.$Enums.PlanStatus = {
  Active: 'Active',
  Inactive: 'Inactive'
};

exports.Entitlement = exports.$Enums.Entitlement = {
  Discover: 'Discover',
  Investment: 'Investment',
  ProposalGeneration: 'ProposalGeneration',
  Integrations: 'Integrations',
  InternalAdvisor: 'InternalAdvisor',
  CompoundAccess: 'CompoundAccess',
  ClientOnboarding: 'ClientOnboarding'
};

exports.SubscriptionStatus = exports.$Enums.SubscriptionStatus = {
  Current: 'Current',
  Canceled: 'Canceled',
  PastDue: 'PastDue'
};

exports.ReportStatus = exports.$Enums.ReportStatus = {
  Processing: 'Processing',
  Complete: 'Complete',
  Failed: 'Failed'
};

exports.TradableEventType = exports.$Enums.TradableEventType = {
  ModelChange: 'ModelChange',
  StrategyChange: 'StrategyChange',
  AllocationChange: 'AllocationChange',
  AccountChange: 'AccountChange'
};

exports.SavedItemType = exports.$Enums.SavedItemType = {
  PrivateInvestment: 'PrivateInvestment'
};

exports.FundHolderOption = exports.$Enums.FundHolderOption = {
  Fidelity: 'Fidelity',
  Schwab: 'Schwab',
  Sponsor: 'Sponsor',
  Unknown: 'Unknown',
  Other: 'Other'
};

exports.DocusignAuthType = exports.$Enums.DocusignAuthType = {
  None: 'None',
  KnowledgeBased: 'KnowledgeBased',
  SMSBased: 'SMSBased',
  Unknown: 'Unknown'
};

exports.AnvilEnvelopeSignerStatus = exports.$Enums.AnvilEnvelopeSignerStatus = {
  Unsent: 'Unsent',
  Unopened: 'Unopened',
  Opened: 'Opened',
  Signed: 'Signed'
};

exports.AnvilEnvelopeDestination = exports.$Enums.AnvilEnvelopeDestination = {
  Client: 'Client',
  Ops: 'Ops'
};

exports.AnvilEnvelopeType = exports.$Enums.AnvilEnvelopeType = {
  AlternativASA: 'AlternativASA',
  FidelityAddRemoveAdvisor: 'FidelityAddRemoveAdvisor',
  FidelityAddRemoveAdvisorStandalone: 'FidelityAddRemoveAdvisorStandalone',
  FidelityNewAccount: 'FidelityNewAccount',
  SchwabLPOA: 'SchwabLPOA',
  SchwabLPOAStandalone: 'SchwabLPOAStandalone',
  SchwabPersonalAccount: 'SchwabPersonalAccount',
  SchwabNewAccountShortForm: 'SchwabNewAccountShortForm',
  AlternativInvestmentCompliance: 'AlternativInvestmentCompliance',
  AlternativRisk: 'AlternativRisk'
};

exports.AnvilEnvelopeStatus = exports.$Enums.AnvilEnvelopeStatus = {
  NotStarted: 'NotStarted',
  Started: 'Started',
  InProgress: 'InProgress',
  Unsent: 'Unsent',
  AwaitingSignatures: 'AwaitingSignatures',
  Complete: 'Complete'
};

exports.AnvilEnvelopeAction = exports.$Enums.AnvilEnvelopeAction = {
  Email: 'Email',
  Ticket: 'Ticket'
};

exports.OnboardingAccountStatus = exports.$Enums.OnboardingAccountStatus = {
  Missing: 'Missing',
  Complete: 'Complete',
  Deleted: 'Deleted'
};

exports.TicketSource = exports.$Enums.TicketSource = {
  Salesforce: 'Salesforce'
};

exports.TicketStatus = exports.$Enums.TicketStatus = {
  Open: 'Open',
  Completed: 'Completed',
  Closed: 'Closed'
};

exports.SalesforceTicketType = exports.$Enums.SalesforceTicketType = {
  Case: 'Case',
  Task: 'Task',
  TradeTicket: 'TradeTicket'
};

exports.TicketType = exports.$Enums.TicketType = {
  AccountMaintenance: 'AccountMaintenance',
  Cashiering: 'Cashiering',
  CashRaise: 'CashRaise',
  CashReserves: 'CashReserves',
  Distributions: 'Distributions',
  Documentation: 'Documentation',
  FeeBilling: 'FeeBilling',
  IncidentResolution: 'IncidentResolution',
  ManagementStatus: 'ManagementStatus',
  Miscellaneous: 'Miscellaneous',
  ModelAssign: 'ModelAssign',
  ModelChange: 'ModelChange',
  NewAccount: 'NewAccount',
  Other: 'Other',
  SecurityRestrictions: 'SecurityRestrictions',
  StrategyChange: 'StrategyChange',
  SubDoc: 'SubDoc',
  TransferAssets: 'TransferAssets',
  TradeForm: 'TradeForm',
  TradeNotes: 'TradeNotes',
  CapitalGainsBudgetUpdate: 'CapitalGainsBudgetUpdate',
  RegistrationWithMultipleAccounts: 'RegistrationWithMultipleAccounts'
};

exports.TeamRoleType = exports.$Enums.TeamRoleType = {
  Advisor: 'Advisor',
  Billing: 'Billing',
  ClientManager: 'ClientManager',
  Engineer: 'Engineer',
  Operations: 'Operations',
  Product: 'Product',
  Sales: 'Sales',
  Tax: 'Tax',
  Trading: 'Trading'
};

exports.PermissionScope = exports.$Enums.PermissionScope = {
  Global: 'Global',
  Team: 'Team'
};

exports.PermissionEntity = exports.$Enums.PermissionEntity = {
  AccountGroup: 'AccountGroup',
  InvestmentModel: 'InvestmentModel',
  InvestmentStrategy: 'InvestmentStrategy',
  Team: 'Team',
  TeamMember: 'TeamMember'
};

exports.PermissionAction = exports.$Enums.PermissionAction = {
  View: 'View',
  Edit: 'Edit',
  Delete: 'Delete',
  Create: 'Create',
  Admin: 'Admin',
  Trade: 'Trade'
};

exports.EmailSource = exports.$Enums.EmailSource = {
  AdvisorHQ: 'AdvisorHQ',
  Nylas: 'Nylas'
};

exports.MailboxType = exports.$Enums.MailboxType = {
  Primary: 'Primary',
  Marketing: 'Marketing',
  Transactional: 'Transactional'
};

exports.MailboxSyncState = exports.$Enums.MailboxSyncState = {
  InProgress: 'InProgress',
  Complete: 'Complete',
  Failed: 'Failed'
};

exports.MailboxSyncType = exports.$Enums.MailboxSyncType = {
  Backfill: 'Backfill',
  DailyRefresh: 'DailyRefresh',
  Webhook: 'Webhook'
};

exports.LeadContactStatus = exports.$Enums.LeadContactStatus = {
  New: 'New',
  Unqualified: 'Unqualified',
  UnqualifiedFutureLead: 'UnqualifiedFutureLead',
  ReachedOut: 'ReachedOut',
  ContactedNotInterested: 'ContactedNotInterested',
  ContactedNeverResponded: 'ContactedNeverResponded',
  ContactedInterested: 'ContactedInterested'
};

exports.LeadSource = exports.$Enums.LeadSource = {
  Firm: 'Firm',
  Advisor: 'Advisor'
};

exports.LeadSourceCategory = exports.$Enums.LeadSourceCategory = {
  Clay: 'Clay',
  LeadForm: 'LeadForm',
  SignupLink: 'SignupLink',
  Advertisement: 'Advertisement',
  CustomerEvent: 'CustomerEvent',
  EmployeeReferral: 'EmployeeReferral',
  GoogleAdWords: 'GoogleAdWords',
  Inbound: 'Inbound',
  Other: 'Other',
  Outbound: 'Outbound',
  Partner: 'Partner',
  PurchasedList: 'PurchasedList',
  Referral: 'Referral',
  TradeShow: 'TradeShow',
  Webinar: 'Webinar',
  Website: 'Website'
};

exports.Prisma.ModelName = {
  Opportunity: 'Opportunity',
  Valuation: 'Valuation',
  Compliance: 'Compliance',
  Liquidity: 'Liquidity',
  Distribution: 'Distribution',
  Document: 'Document',
  Organization: 'Organization',
  DocumentLink: 'DocumentLink',
  Asset: 'Asset',
  InvestmentTransaction: 'InvestmentTransaction',
  PerformanceBreakdown: 'PerformanceBreakdown',
  AssetSnapshot: 'AssetSnapshot',
  AlternativeAsset: 'AlternativeAsset',
  Account: 'Account',
  FeeSchedule: 'FeeSchedule',
  AccountRepresentative: 'AccountRepresentative',
  RepresentativePayable: 'RepresentativePayable',
  PayableClient: 'PayableClient',
  PayableAccount: 'PayableAccount',
  AccountAddress: 'AccountAddress',
  SecurityAccountControl: 'SecurityAccountControl',
  DistributionSchedule: 'DistributionSchedule',
  Role: 'Role',
  User: 'User',
  AccountGroup: 'AccountGroup',
  AccountGroupMetaData: 'AccountGroupMetaData',
  Note: 'Note',
  MeetingLog: 'MeetingLog',
  MeetingSummaryItem: 'MeetingSummaryItem',
  Client: 'Client',
  ContactEmail: 'ContactEmail',
  Employee: 'Employee',
  EmployeePreferences: 'EmployeePreferences',
  Questionnaire: 'Questionnaire',
  QuestionGroup: 'QuestionGroup',
  QuestionOption: 'QuestionOption',
  QuestionAnswer: 'QuestionAnswer',
  QuestionnaireSubmission: 'QuestionnaireSubmission',
  Proposal2: 'Proposal2',
  Proposal2Asset: 'Proposal2Asset',
  ProposalProxySecurity: 'ProposalProxySecurity',
  ProposalAlternative: 'ProposalAlternative',
  DefaultProposalAlternatives: 'DefaultProposalAlternatives',
  Proposal2Content: 'Proposal2Content',
  AnvilEnvelopeLinkCopy: 'AnvilEnvelopeLinkCopy',
  ClientLink: 'ClientLink',
  InvestmentModelWhitelist: 'InvestmentModelWhitelist',
  InvestmentModel: 'InvestmentModel',
  InvestmentModelStrategy: 'InvestmentModelStrategy',
  InvestmentStrategy: 'InvestmentStrategy',
  Provider: 'Provider',
  PortfolioManager: 'PortfolioManager',
  InvestmentStrategySecurity: 'InvestmentStrategySecurity',
  SectorWeight: 'SectorWeight',
  Security: 'Security',
  SecurityDailyPrice: 'SecurityDailyPrice',
  AssetClassification: 'AssetClassification',
  SubAssetClassification: 'SubAssetClassification',
  SubAssetClassificationOverride: 'SubAssetClassificationOverride',
  Plan: 'Plan',
  Subscription: 'Subscription',
  Job: 'Job',
  TradingReportRun: 'TradingReportRun',
  TradableEvent: 'TradableEvent',
  SavedList: 'SavedList',
  FundHolder: 'FundHolder',
  FundClosingSubscriptionDocument: 'FundClosingSubscriptionDocument',
  AnvilEnvelopeSigner: 'AnvilEnvelopeSigner',
  AnvilEnvelope: 'AnvilEnvelope',
  AnvilEnvelopeTypeData: 'AnvilEnvelopeTypeData',
  OnboardingAccount: 'OnboardingAccount',
  ClientOnboarding: 'ClientOnboarding',
  SecuritySourceData: 'SecuritySourceData',
  SecurityDailyResult: 'SecurityDailyResult',
  Ticket: 'Ticket',
  Team: 'Team',
  TeamRole: 'TeamRole',
  PermissionTemplate: 'PermissionTemplate',
  TeamMember: 'TeamMember',
  EmailMessage: 'EmailMessage',
  Mailbox: 'Mailbox',
  MailboxSync: 'MailboxSync',
  EmailMessageThread: 'EmailMessageThread',
  EmailAttachment: 'EmailAttachment',
  Lead: 'Lead',
  LeadMetaData: 'LeadMetaData',
  AccountGroupBilledAUM: 'AccountGroupBilledAUM',
  AccountGroupBalance: 'AccountGroupBalance',
  ClientAccountAggregation: 'ClientAccountAggregation',
  Permission: 'Permission'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
