export type Optional<T> = T | undefined | null;

export type KeyGetter<ItemType> = (item: ItemType) => PropertyKey;

export type Matcher<ItemType> = (item: ItemType) => ItemType | ItemType[keyof ItemType];

export type Predicate<T> = (item: T) => boolean;

/**
 * Build a type that is a union of all of the property names with the given type.
 */
export type PropertiesByType<Object, PropertyType> = {
  [K in keyof Object]-?: Object[K] extends PropertyType ? K : never;
}[keyof Object];

export type EnumMap<T extends { [key: symbol]: string }> = {
  readonly [P in keyof T]: string;
};

// Nulls are annoying to work with... If we encounter them, this will set it to undefined instead
export const isNotNull = <T>(value: T | null): T | undefined => value ?? undefined;

export const isNotEmptyOrFail = <T>(item: Optional<T>, error?: Error): T => {
  if (notEmpty(item)) {
    return item;
  }

  // Defer creating the fallback error unless we actually need it.
  throw error ?? new Error("Expected value to not be empty");
};

export const isNotNullOrFail = <T>(item?: T, error?: Error): NonNullable<T> => {
  if (item) {
    return item as NonNullable<T>;
  }
  throw error ?? new Error("Expected value to not be null");
};

export function isNil<T>(value: T | null | undefined): value is undefined | null {
  return value === null || value === undefined;
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return !isNil(value);
}

export const emptyString = (value?: string | null): value is undefined | null | "" =>
  isNil<string>(value) || value.length === 0;

export const notEmptyString = (value?: string | null): value is string =>
  notEmpty<string>(value) && value.length > 0;

export const isValueOfEnum = <ValueType, EnumType extends Record<string, ValueType>>(
  value: ValueType,
  enumType: EnumType
): value is EnumType[keyof EnumType] => Object.values(enumType).includes(value);

// Proper numerical rounding: http://www.jacklmoore.com/notes/rounding-in-javascript/
export const round = (value: number, decimalPlaces: number) =>
  Number(Math.round(parseFloat(value + "e" + decimalPlaces)) + "e-" + decimalPlaces);

// It's only desirable to use this rounded utility for display. Don't use it before performing calculations.
export const roundedTo2DecimalPlaces = (toBeRounded: number) => round(toBeRounded, 2);

export const splitCamelCase = (text: string) => {
  return text.replace(/([A-Z])/g, "$1").replace(/^./, (str) => {
    return str.toUpperCase();
  });
};

export const isString = (str?: any): str is string => typeof str === "string";

/**
 * Returns an array with arrays of the given size.
 *
 * @param arr {Array} array to split
 * @param size {Integer} Size of every group
 */
export function chunk<T>(arr: T[], size: number): T[][] {
  let index = 0;
  const arrayLength = arr.length;
  const result: T[][] = [];

  for (index = 0; index < arrayLength; index += size) {
    result.push(arr.slice(index, index + size));
  }

  return result;
}

/**
 * Return the given value unaltered.
 */
export const Identity = <T>(t: T): T => t;

export function getEnumValue<T extends EnumMap<T>>(enumMap: T, str: string): any {
  const enumKeys = Object.keys(enumMap) as Array<keyof T>;
  for (const key of enumKeys) {
    if (enumMap[key] === str) {
      return key;
    }
  }
  return undefined;
}

export const queryStringFromUrl = (url?: string, token: string = "?"): string => {
  if (url?.includes(token)) {
    return url.substring(url.indexOf(token) + token.length);
  }
  return "";
};

// https://stackoverflow.com/a/43001581
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };
