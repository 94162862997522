import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { ReactElement, useEffect, useState } from "react";
import { LoadingIcon } from "components/fundamentals/icons/LoadingIcon";

export type AlertType = "INFO" | "ERROR" | "SUCCESS" | "LOADING";
export type AlertBehavior = "PERSISTENT" | "TOAST";

/*
 * List all possible Tailwind classes for the different Alert banners here... We could maybe add this to a safelist in the tailwind config
 *
 * bg-red-100
 * bg-yellow-100
 * bg-green-100
 * bg-gray-100
 *
 * text-red-800
 * text-yellow-800
 * text-green-800
 * text-gray-800
 *
 * text-red-700
 * text-yellow-700
 * text-green-700
 * text-gray-700
 *
 * text-red-500
 * text-yellow-500
 * text-green-500
 * text-gray-500
 *
 * text-red-400
 * text-yellow-400
 * text-green-400
 * text-gray-400
 *
 * hover:bg-red-100
 * hover:bg-yellow-100
 * hover:bg-green-100
 * hover:bg-gray-100
 *
 * focus:ring-offset-red-50
 * focus:ring-offset-yellow-50
 * focus:ring-offset-green-50
 * focus:ring-offset-gray-50
 *
 * focus:ring-red-600
 * focus:ring-yellow-600
 * focus:ring-green-600
 * focus:ring-gray-600
 */
const getColor = (type: AlertType): string => {
  if (type === "ERROR") {
    return "red";
  }
  if (type === "SUCCESS") {
    return "green";
  }
  if (type === "INFO") {
    return "yellow";
  }
  if (type === "LOADING") {
    return "gray";
  }

  const exhaustiveCheck: never = type;
  throw new Error(exhaustiveCheck);
};

const getIcon = (type: AlertType): ReactElement => {
  if (type === "ERROR") {
    return (
      <ExclamationTriangleIcon
        className={`text-${getColor(type)}-400 h-5 w-5`}
        aria-hidden="true"
      />
    );
  }
  if (type === "SUCCESS") {
    return <CheckCircleIcon className={`text-${getColor(type)}-400 h-5 w-5`} aria-hidden="true" />;
  }
  if (type === "INFO") {
    return (
      <InformationCircleIcon className={`text-${getColor(type)}-400 h-5 w-5`} aria-hidden="true" />
    );
  }
  if (type === "LOADING") {
    return <LoadingIcon />;
  }

  const exhaustiveCheck: never = type;
  throw new Error(exhaustiveCheck);
};

export interface AlertProps {
  readonly message: React.ReactNode;
  readonly title?: string;
  readonly alertType: AlertType;
  readonly alertBehavior?: AlertBehavior;
  readonly closeable?: boolean;
  readonly onClose?: () => void;
  readonly dataAlert?: string;
  readonly refElement?: React.RefObject<HTMLDivElement>;
}

/*
  The below alert was mostly copied/pasted/combined based on the Alert React examples here:
  https://tailwindui.com/components/application-ui/feedback/alerts
*/
export const Alert = (props: AlertProps) => {
  const color = getColor(props.alertType);
  const { alertBehavior, onClose } = props;
  useEffect(() => {
    if (alertBehavior === "TOAST" && onClose) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, [alertBehavior, onClose]);

  return (
    <div
      className={`rounded-md bg-${color}-100 p-4`}
      data-alert={props.dataAlert}
      ref={props.refElement}
    >
      <div className="flex items-center">
        {props.alertType !== "LOADING" && (
          <div className="flex-shrink-0">{getIcon(props.alertType)}</div>
        )}
        <div className={props.alertType === "LOADING" ? "mr-2" : "ml-2"}>
          {props.title && (
            <h3 className={`text-sm font-medium text-${color}-800`}>{props.title}</h3>
          )}
          <div className={`text-sm text-${color}-700`}>{props.message}</div>
        </div>
        {props.alertType === "LOADING" && (
          <div className="flex-shrink-0">{getIcon(props.alertType)}</div>
        )}
        {props.closeable && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={`inline-flex bg-${color}-100 rounded-md p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
                onClick={props.onClose}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Primarily used for ensuring only one banner for each action is present. Could add whatever to this list.
// Also serves the purpose for having a unique Key in a React List
type AlertIdentifier =
  | "account"
  | "email"
  | "summaryText"
  | "welcomeText"
  | "orgEdit"
  | "signUp"
  | "layout"
  | "subscriptionEnvelope"
  | "emailVerify"
  | "accountMutationError"
  | "loadingError"
  | "investmentProvider"
  | "clientCreate"
  | "eclipseExport"
  | "modelUpdate"
  | "repEdit";
export interface AlertBannerAlertProps extends Omit<AlertProps, "onClose"> {
  readonly key: AlertIdentifier;
}

// Convenient helper function to help set some "standard defaults" for success banners
export const getDefaultSuccessAlertProps = (
  key: AlertIdentifier,
  message: React.ReactNode
): AlertBannerAlertProps => ({
  key,
  alertType: "SUCCESS",
  message,
  alertBehavior: "TOAST",
});

// Convenient helper function to help set some "standard defaults" for error banners
export const getDefaultErrorAlertProps = (
  key: AlertIdentifier,
  message: React.ReactNode
): AlertBannerAlertProps => ({
  key,
  alertType: "ERROR",
  message,
  alertBehavior: "PERSISTENT",
  closeable: true,
});

//
const CloseableAlert = (props: AlertBannerAlertProps) => {
  const [visible, setVisible] = useState(true);

  if (visible) {
    return (
      <Alert
        alertType={props.alertType}
        message={props.message}
        closeable={props.closeable}
        onClose={() => setVisible(false)}
        title={props.title}
        alertBehavior={props.alertBehavior}
        dataAlert={props.dataAlert}
      />
    );
  }
  return null;
};

interface AlertBannerProps {
  readonly alerts: AlertBannerAlertProps[];
  readonly refElement?: React.RefObject<HTMLDivElement>;
}

export const AlertBanner = (props: AlertBannerProps) => (
  <div className="space-y-2" ref={props.refElement}>
    {props.alerts.map((alertProps: AlertBannerAlertProps) => (
      <CloseableAlert
        key={alertProps.key}
        alertType={alertProps.alertType}
        message={alertProps.message}
        closeable={alertProps.closeable}
        title={alertProps.title}
        alertBehavior={alertProps.alertBehavior}
        dataAlert={alertProps.dataAlert}
      />
    ))}
  </div>
);
